import html2canvas from 'html2canvas';
import { getEstimateStore } from '../data/DataStores';
import { logDebug } from '../shared/logger';
import jsPDF from 'jspdf';

export default class PrivateUtils {
	static exportPDF() {
		getEstimateStore().setCreatingPDF(true);
		setTimeout(async () => {
			const canvases: HTMLCanvasElement[] = [];
			const topEstimatePanel = document.getElementById('topEstimatePanel');
			const canvas = await html2canvas(topEstimatePanel!);
			canvases.push(canvas);
			for (let i = 0; i < getEstimateStore().roomNames.length; i++) {
				const element = document.getElementById(`scrollPage-${i}`);
				const canvas = await html2canvas(element!);
				canvases.push(canvas);
			}

			// const pdfWidth = 210; // Width of A4 in mm
			const props = {
				orientation: 'landscape',
				unit: 'px',
				format: [canvases.reduce((acc, canvas) => acc + canvas.height, 0) + 200, canvas.width + 200]
			}
			logDebug('pdf props', props);
			const pdf = new jsPDF(props as any);
			let accuHeight = 0;
			canvases.forEach((canvas, index) => {
				const imgData = canvas.toDataURL('image/png');
				// logDebug(index, 'imgData', imgData)
				logDebug(`adding to canva ${index}`, accuHeight, canvas.width, canvas.height);
				pdf.addImage(imgData, 'PNG', 100, accuHeight, canvas.width, canvas.height);
				accuHeight += canvas.height;
			});

			pdf.save(`${getEstimateStore().insuredLast || getEstimateStore().insured} Estimate.pdf`);
			getEstimateStore().setCreatingPDF(false);
			// // const inputElement = document.getElementById('content-to-export'); // Adjust the ID to match your content's container
			// 	html2canvas(pdfDivRef.current!).then((canvas: any) => {
			// 		const imgData = canvas.toDataURL('image/png');
			// 		const canvasAspectRatio = canvas.width / canvas.height;
			// 		const pdfWidth = 210; // Width of A4 in mm
			// 		const pdfHeight = pdfWidth / canvasAspectRatio; // Calculate height based on the canvas aspect ratio

			// 		const pdf = new jsPDF({
			// 			orientation: canvas.width > canvas.height ? 'landscape' : 'portrait',
			// 			unit: 'mm',
			// 			format: [pdfWidth, pdfHeight * 2]
			// 		});

			// 		// Add the canvas image to the PDF with the same aspect ratio
			// 		pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
			// 		pdf.addImage(imgData, 'PNG', 0, pdfHeight, pdfWidth, pdfHeight);
			// 		pdf.save('download.pdf');
			// 		estimateStore.current.setCreatingPDF(false);
			// 	});
		}, 30);
	};

}
