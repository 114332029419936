import { getAuth } from 'firebase/auth';
import { logDebug, logError } from '../shared/logger';

export default class RestAPI {

	public static DEV_SERVER_BASE_URL = 'http://localhost:9083';
	public static PROD_SERVER_BASE_URL = 'https://estimates.golasso.ai';
	public static SERVER_BASE_URL = process.env.NODE_ENV !== 'production' ? RestAPI.DEV_SERVER_BASE_URL : RestAPI.PROD_SERVER_BASE_URL;
	public static PUBLIC_BASE_URL = `${RestAPI.SERVER_BASE_URL}/output`;
	public static API_URL = `${RestAPI.SERVER_BASE_URL}/rest/v1`;


	public static getServerBaseUrl(path: string): string {
		if (path.startsWith('/')) {
			path = path.substring(1);
		}
		if (path.startsWith('home/lasso/output')) {
			return `${RestAPI.PROD_SERVER_BASE_URL}/${path}`;
		}
		return `${RestAPI.SERVER_BASE_URL}/${path}`;
	}

	static async fetchAPI(api: string, method = 'GET', params: any = {}, dataField = 'data'): Promise<any> {
		logDebug('fetchAPI', api, method, params);
		if (Object.keys(params).length > 0 && method === 'GET') {
			api += '?';
			Object.entries(params).forEach(([key, value]) => {
				api += `${key}=${value}&`;
			});
			params = {};
		}
		return await this.fetchURL(`${RestAPI.API_URL}/${api}`, method, params, dataField);
	}
	static async getIdToken(auth: any): Promise<string | null> {
		if (auth.currentUser) {
			try {
				return await auth.currentUser.getIdToken();
			} catch (err) {
				logError('Error in getIdToken :', err);
			}
		}
		return null;
	}

	static async fetchURL(url: URL | string, method = 'GET', params: any = {}, dataField = 'data', sendAuth = true): Promise<any> {
		if (typeof url === 'string') {
			url = new URL(url);
		}
		let authToken = 'none';
		let uid = 'none';
		let response;
		if (sendAuth) {
			const auth = getAuth();
			authToken = await this.getIdToken(auth) ?? '';
			uid = auth.currentUser!.uid;
		}
		if (method === 'POST') {
			response = await fetch(url, {
				method,
				body: JSON.stringify(params),
				headers: {
					'Content-Type': 'application/json;charset=UTF-8',
					'Accept': 'application/json',
					'authorization': 'Bearer ' + authToken,
					'uid': uid
				},
			});
		} else {
			try {
				response = await fetch(url, {
					method,
					headers: {
						'Content-Type': 'application/json;charset=UTF-8',
						'Accept': 'application/json',
						'authorization': 'Bearer ' + authToken,
						'uid': uid
					},
				});
			} catch (error) {
				return null;
			}
		}
		try {
			const data: any = await response.json();
			if (dataField && dataField in data) {
				return data[dataField];
			}
			return data;
		} catch (error) {
			try {
				const text: any = await response.text();
				return text;
			} catch (err) {
				logError('Error reading text from url :', err);
			}
		}
		return null
	}

}