import LassoUserImage from './LassoUserImage';
import './LassoTopBar.scss';
import { useRef } from 'react';
import { getEstimateStore } from '../../../data/DataStores';
import { observer } from 'mobx-react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { logDebug } from '../../../shared/logger';
import PrivateUtils from '../../../utils/PrivateUtils';

const LassoUserBar: React.FC = observer(() => {
	const estimateStore = useRef(getEstimateStore());
	return (
		<div className='bar-frame '>
			<img src='/assets/ui/Logomark.png' width='32' height='32' alt='Lasso' />
			<div className='lasso ml-2'>Lasso</div>
			<div className='breadcrumbs flex items-center'>
				<img src='/assets/ui/home-line.svg' width='24' alt='Lasso' />
				<img src='/assets/ui/chevron-right.svg' width='20' alt='Lasso' />
				<div className='dashboard'>Dashboard</div>
				<img src='/assets/ui/chevron-right.svg' width='20' alt='Lasso' />
				<div className='dashboard'>{estimateStore.current.insuredLast} Estimate</div>
			</div>
			<div className='right-bar'>
				{/* <img className='cursor-pointer' src='/assets/ui/export.svg' width='24' alt='Lasso' onClick={() => {
					PrivateUtils.exportPDF();
				}} /> */}
				<img src='/assets/ui/settings-01.svg' width='24' alt='Lasso' />
				<img src='/assets/ui/bell-01.svg' width='24 ' alt='Lasso' />
				<LassoUserImage />
			</div>
		</div>
	);
});

const LassoNavigationBar: React.FC = observer(() => {
	const store = useRef(getEstimateStore());
	function setCurrentView(view: string) {
		const supportedViews = ['Estimate',
			'Photos',
			// 'Scope Note',
			'Tic Sheet',
			// 'Floor Plan', '3D View'
		];
		if (supportedViews.includes(view)) {
			store.current.setCurrentView(view);
		}

	}
	const views = ['Estimate', 'Photos', 'Scope Note', 'Tic Sheet', 'Floor Plan', '3D View'];
	return <div className='flex navigation'>
		{views.map((view, index) => {
			return <div key={index} className={`navigation-cell ${store.current.currentView === view ? 'selected' : ''}`} onClick={() => setCurrentView(view)}>{view}</div>
		})}
		{/* <div className={`navigation-cell selected`}>Estimate</div>
		<div className='navigation-cell'>Scope Note</div>
		<div className='navigation-cell'>Tic Sheet</div>
		<div className='navigation-cell'>Floor Plan</div>
		<div className='navigation-cell'>3D View</div> */}
	</div>
});


const LassoTopBar: React.FC = () => {
	return <div className='mr-8 ml-8'>
		<LassoUserBar />
		<LassoNavigationBar />
	</div>
}

export default LassoTopBar;
