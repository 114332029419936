import React from 'react';
import { logDebug } from '../../shared/logger';


const UploadFileButton: React.FC<{ onLoad?: (base64Data: string) => void, accept?: string, title?: string }> = ({ onLoad, accept, title }) => {
	// const [file, setFile] = useState<File | null>(null);
	// const [fileUrl, setFileUrl] = useState<string | null>(null);
	const buttonRef = React.useRef<HTMLInputElement>(null);
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		logDebug('handleFileChange', event.target.files);
		const selectedFile = event.target.files?.[0];
		if (selectedFile) {
			// setFile(selectedFile);
			// setFileUrl(URL.createObjectURL(selectedFile));
			const reader = new FileReader();
			if (accept === 'application/json') {
				reader.readAsText(selectedFile);
				reader.onload = () => {
					onLoad?.(reader.result as string);
				};
			} else {
				reader.readAsDataURL(selectedFile);
				reader.onload = () => {
					onLoad?.(reader.result as string);
				};
			}
		}
	};

	return (
		<div>
			{title && <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2 w-[160px]" onClick={() => buttonRef.current?.click()}>{title}</button>}
			<input hidden={!!title} ref={buttonRef} type="file" title='lala' onChange={handleFileChange} accept={accept} />
		</div>
	);
}

export default UploadFileButton;