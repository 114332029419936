import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { debugStyle, getEstimateStore } from '../../../data/DataStores';
import RestAPI from '../../../utils/restAPI';

const LassoTicSheetPanel: React.FC = observer(() => {
	const [imageSize, setImageSize] = useState<number[]>([0, 0]);
	const imageRef = useRef<HTMLImageElement>(null);

	const estimateStore = useRef(getEstimateStore());
	return <div className={debugStyle('ml-[73px] mr-[73px]', 'bg-blue-100')}>
		<div className='flex' onClick={() => estimateStore.current.setCurrentView('Estimate')} >
			<img src='/assets/ui/arrow-left.svg' className='ml-4 mt-4 cursor-pointer' />
			<div className='ml-2 mt-4 cursor-pointer'>Back to line item</div>
		</div>
		<div style={{ position: 'relative', display: 'inline-block' }}>
			<img
				onLoad={(img: any) => {
					setImageSize([img.target.naturalWidth, img.target.naturalHeight]);
				}}
				ref={imageRef}
				src={estimateStore.current.pageImageURL!}
				id="sourceImage"
				style={{ display: 'block', width: 1000, height: 'auto' }}
			/>
			{
				estimateStore.current.ticSheetHighlight.length > 0 && imageSize[0] > 0 &&
				<svg style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}
					width={1000}
					height={1000 * (imageSize[1] / imageSize[0])}
				>
					<rect
						x={estimateStore.current.ticSheetHighlight[0] * (1000 / imageSize[0])}
						y={estimateStore.current.ticSheetHighlight[1] * (1000 / imageSize[0])}
						width={estimateStore.current.ticSheetHighlight[2] * (1000 / imageSize[0])}
						height={estimateStore.current.ticSheetHighlight[3] * (1000 / imageSize[0])}
						stroke="red"
						fill="none"
						strokeWidth="2" />
				</svg>
			}
			{
				estimateStore.current.ticSheetHighlight.length === 0 && estimateStore.current.pages.length > 0 && <div>
					{
						estimateStore.current.pages.map((page, index) => {
							return <div key={index} >
								<img src={RestAPI.getServerBaseUrl(page.pngFile!)} width='100%' />
							</div>
						})

					}
				</div>
			}
		</div>
	</div>
});

export default LassoTicSheetPanel;