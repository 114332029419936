import { useEffect, useRef, useState } from 'react';
import { getEstimateStore } from '../../../data/DataStores';
import { logDebug } from '../../../shared/logger';
import SharedUtils from '../../../shared/SharedUtils';

const nameToSizeMap: { [k: string]: { w: number, h: number } } =
{
	'Closet': { w: 11.1, h: 13.6 },
	'Basement': { w: 14.4, h: 13.4 },
};
const defaultSize = { w: 15.1, h: 13.6 };
const LassoAreaDialog: React.FC<{ onDone: () => void }> = ({ onDone }) => {
	const estimateStore = useRef(getEstimateStore());
	const [phase, setPhase] = useState<number>(0);
	const [d0s, setD0s] = useState<string[]>([]);
	const [d1s, setD1s] = useState<string[]>([]);

	useEffect(() => {
		if (estimateStore.current.roomNames.length === 0) {
			onDone();
		}
	}, [estimateStore.current.roomNames]);

	function _setD0s(i: number, value: string) {
		const newD0s = [...d0s];
		newD0s[i] = value;
		setD0s(newD0s);
	}
	function _setD1s(i: number, value: string) {
		const newD1s = [...d1s];
		newD1s[i] = value;
		setD1s(newD1s);
	}

	function nextPhase() {
		if (phase === 0) {
			const _d0s: string[] = [];
			const _d1s: string[] = [];
			estimateStore.current.roomNames.forEach((name, i) => {
				_d0s.push(SharedUtils.sizeString((nameToSizeMap[estimateStore.current.roomNames[i]] || defaultSize).w));
				_d1s.push(SharedUtils.sizeString((nameToSizeMap[estimateStore.current.roomNames[i]] || defaultSize).h));
			});
			setD0s(_d0s);
			setD1s(_d1s);
			setPhase(1);
		}
		else if (phase === 1) {
			estimateStore.current.roomNames.forEach((name, i) => {
				let d0 = parseFloat(d0s[i].split("'")[0] || '0') + parseFloat(d0s[i].split("'")[1] || '0') / 12;
				let d1 = parseFloat(d1s[i].split("'")[0] || '0') + parseFloat(d1s[i].split("'")[1] || '0') / 12;
				d0 = Math.round(d0 * 10) / 10;
				d1 = Math.round(d1 * 10) / 10;
				logDebug('d0', d0, d1);
				estimateStore.current.setRoomDimensions(i, { w: d0, h: d1 });
			});
			onDone();
		}
	}
	return <div style={{
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		zIndex: 1000,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}}>

		{
			phase === 0 && <div className="w-[400px] h-[468px] bg-white rounded-xl shadow flex-col justify-start items-center inline-flex">
				<div className="self-stretch h-[232px] px-6 pt-6 flex-col justify-start items-center gap-5 flex">
					<div className="w-[352px] pl-[45px] pr-11 pt-7 bg-gray-100 rounded-lg justify-center items-center inline-flex">
						<img className="w-[263px] h-[261px]" src="/assets/ui/room.png" />
					</div>
				</div>
				<div className="self-stretch h-[136px] bg-white flex-col justify-start items-center flex">
					<div className="self-stretch h-[116px] px-6 pt-6 bg-white flex-col justify-start items-center gap-4 flex">
						<div className="self-stretch h-[92px] flex-col justify-start items-center gap-1 flex">
							<div className="self-stretch text-center text-gray-900 text-lg font-semibold font-['Inter'] leading-7">Key Data Check</div>
							<div className="self-stretch text-center text-slate-600 text-sm font-normal font-['Inter'] leading-tight">To make sure that we get your estimate started on the right foot, please check these data points the AI has extracted. </div>
						</div>
					</div>
					<div className="self-stretch h-5" />
				</div>
				<div className="self-stretch h-[100px] pt-8 flex-col justify-start items-start flex">
					<div className="self-stretch px-6 pb-6 justify-start items-start gap-3 inline-flex">
						<div onClick={() => nextPhase()} className="cursor-pointer grow shrink basis-0 h-11 px-[18px] py-2.5 bg-blue-900 rounded-lg shadow border border-blue-900 justify-center items-center gap-2 flex">
							<div className="text-white text-base font-semibold font-['Inter'] leading-normal">Next</div>
						</div>
					</div>
				</div>
			</div>
		}
		{
			phase > 0 && <div className="w-[400px] h-[468px] bg-white rounded-xl shadow flex-col justify-start items-center inline-flex">
				<div className="self-stretch h-[232px] px-6 pt-6 flex-col justify-start items-center gap-5 flex">
					<div className="w-[352px] pl-[45px] pr-11 pt-7 bg-gray-100 rounded-lg justify-center items-center inline-flex">
						<img className="w-[263px] h-[261px]" src="/assets/ui/room2.png" />
						<div className='relative'>
							<div className='absolute top-[-50px] left-[-260px] w-[255px] flex justify-center'>
								<div>
									<div className="text-center text-slate-600 text-lg font-semibold font-['Inter'] leading-7">Please fix or confirm the room's dimensions</div>
									{/* <div className="text-center text-slate-600 text-lg font-semibold font-['Inter'] leading-7">{estimateStore.current.roomNames[phase - 1]}</div>
									<div className="text-center text-slate-600 text-sm font-semibold font-['Inter'] leading-tight">{sizeString2(nameToSizeMap[estimateStore.current.roomNames[phase - 1]] || defaultSize)}</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="self-stretch h-[136px] bg-white flex-col justify-start items-center flex">
					<div className="self-stretch h-[116px] px-6 pt-6 bg-white flex-col justify-start items-center gap-4 flex">
						<div className="self-stretch h-[92px] flex-col justify-start items-center gap-1 flex">
							<div className='flex'>
								<div>
									{
										estimateStore.current.roomNames.map((name, i) => {
											return <div className='pr-2 mt-[7px] pb-[7px]'>{name}:</div>
										})
									}
								</div>

								<div className='flex flex-col'>
									{
										estimateStore.current.roomNames.map((name, i) => {
											return <div key={name} className='flex items-center'>
												<input type='text' id='d0' className='p-2 w-[60px] h-[30px] border border-gray-300 rounded-md' value={d0s[i]} onChange={(e) => {
													_setD0s(i, e.target.value);
												}}
												/>
												<div className='m-2'>X</div>
												<input type='text' id='d1' className='p-2 w-[60px] h-[30px] border border-gray-300 rounded-md' value={d1s[i]} onChange={(e) => {
													_setD1s(i, e.target.value);
												}} />
											</div>
										})
									}
								</div>
							</div>
							{/* <div className="self-stretch text-center text-gray-900 text-lg font-semibold font-['Inter'] leading-7">Please fix or confirm the room's dimensions</div> */}
						</div>
					</div>

					<div className="self-stretch h-5" />
				</div>
				<div className="self-stretch h-[100px] pt-8 flex-col justify-start items-start flex">
					<div className="self-stretch px-6 pb-6 justify-start items-start gap-3 inline-flex">
						<div onClick={() => nextPhase()}
							className="cursor-pointer grow shrink basis-0 h-11 px-[18px] py-2.5 bg-blue-900 rounded-lg shadow border border-blue-900 justify-center items-center gap-2 flex">
							<div className="text-white text-base font-semibold font-['Inter'] leading-normal">Confirm</div>
						</div>
					</div>
				</div>
			</div>
		}
	</div>

}

export default LassoAreaDialog;