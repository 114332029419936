import React, { useEffect, useState } from 'react';
import RestAPI from '../utils/restAPI';

import LoadingAnimation from '../components/ui/utils/LoadingAnimation';
import { logDebug, logError } from '../shared/logger';
import { AzureFormResult, Cell, IEstimateResults, IFilledTicSheetData, IJobResults, IVerificationData, IVerificationResults, LineType, TemplateCellTuple, TemplateCellTupleMetadata, TemplateMetaData, constraintsTable } from '../shared/Azure';
import UploadFileButton from '../components/utils/UploadFileButton';
import SharedUtils from '../shared/SharedUtils';
import LassoFilledLinesBrowser from '../components/ui/lasso/LassoFilledLinesBrowser';
import md5 from 'crypto-js/md5';

const EditTemplatePage: React.FC = () => {
	const [page, setPage] = useState<number>(0);
	const [fetching, setFetching] = useState<number>(0);
	const [templateData, setTemplateData] = useState<TemplateMetaData | null>(null);
	const [OverTuple, setOverTuple] = useState<[number, number]>([-1, -1]);
	const [selectedTuple, setSelectedTuple] = useState<[number, number]>([-1, -1]);
	const [workTuple, setWorkTuple] = useState<TemplateCellTuple>();
	const [overWord, setOverWord] = useState<string>('');
	const [forceNewMetaDisplay, setForceNewMetaDisplay] = useState<number>(0);
	const [forceNewButton, setForceNewButton] = useState<number>(0);
	const [testKeyData, setTestKeyData] = useState<string>();
	const [testValueData, setTestValueData] = useState<string>();
	const [testSelectedData, setTestSelectedData] = useState<string>();
	const [testPdfId, setTestPdfId] = useState<string>();
	// const [testEstimateDesc, setTestEstimateDesc] = useState<string>();
	const [testEstimateResult, setTestEstimateResult] = useState<IEstimateResults[]>();
	const [base64PDF, setBase64PDF] = useState<string>();
	const [verificationKey, setVerificationKey] = useState<string>();
	const [verificationMap, setVerificationMap] = useState<{ [k: string]: IVerificationData | false }>({});
	const [errorList, setErrorList] = useState<string[]>([]);

	const [pdfData, setPdfData] = useState<{
		pages: LineType.Page[],
		filledTicSheetData: IFilledTicSheetData[][],
		processId: string,
	}>();

	useEffect(() => {
		if (!workTuple || !(testKeyData || testValueData || testSelectedData)) {
			setVerificationKey('');
			return;
		} else {
			setVerificationKey(md5(`${testPdfId ?? ''}_${page}_${workTuple.meta?.id}_${testKeyData ?? ''}_${testValueData ?? ''}_${testSelectedData ?? ''}`).toString());
		}
	}, [testPdfId, page, workTuple, testKeyData, testValueData, testSelectedData]);


	useEffect(() => {
		if (selectedTuple[0] < 0 || selectedTuple[1] < 0) {
			return;
		}
		// find the tuple
		setTestEstimateResult([]);
		setTestKeyData('');
		setTestValueData('');
		setTestSelectedData('');
		setErrorList([]);
		setForceNewMetaDisplay(forceNewMetaDisplay + 1);
		updateUI();

	}, [selectedTuple]);

	useEffect(() => {
		fetchTemplateData();
	}, []);

	async function fetchTemplateData() {
		if (fetching || templateData) {
			return;
		}
		let _templateData = window.localStorage.getItem('templateData');
		if (_templateData === 'null' || _templateData === 'undefined') {
			_templateData = null;
		}
		if (_templateData) {
			const parsed = JSON.parse(_templateData);
			if (!parsed.azureOriginal) {
				setFetching(v => v + 1);
				try {
					const ret = await RestAPI.fetchAPI('loadTemplateData', 'POST', { base64Pdf: 'X', configJson: 'X' });
					parsed.azureOriginal = ret;
					saveTemplateData(false);
					setFetching(v => v - 1);

				} catch (error) {
					alert('Fetched from server failed');
				}

			}
			setTemplateData(parsed);
		} else {
			loadTemplateData();
		}
		const vm = await RestAPI.fetchAPI('getVerificationMap', 'GET') as { [k: string]: IVerificationData | false };
		// logDebug('getVerificationMap', vm);
		setVerificationMap({ ...vm });
	}

	const loadTemplateData = async (remote = true) => {
		if (!remote) {
			return;
		}
		if (fetching || templateData) {
			return;
		}
		try {
			setFetching(v => v + 1);
			const ret = await RestAPI.fetchAPI('loadTemplateData', 'GET');
			setTemplateData(ret);
			const pdfData = window.localStorage.getItem('base64PDF');
			// if (pdfData && pdfData !== 'null' && pdfData !== 'undefined') {
			// 	loadTestPDF(pdfData);
			// }
			// createTemplateMetaData(ret)
			// setRowsData(ret)
			// setTemplateData(ret);
		} catch (error) {

		}
		setFetching(v => v - 1);
	}
	function createTemplateMetaData(ret: AzureFormResult) {
		const templateMetaData: TemplateMetaData = {
			name: 'Test',
			id: '123',
			header: [],
			rows: [],
		};

		const rows: Cell[][] = Array(ret.tables[0].rowCount + 1);
		rows[0] = [];
		rows[0].push(createNewHeaderCell(-1, 0, 'Address:'));
		rows[0].push(createNewHeaderCell(-1, 1, 'Dash#'));
		rows[0].push(createNewHeaderCell(-1, 2, 'Interior Repair Worksheet'));
		ret.tables[0].cells.forEach((cell) => {
			const useIndex = cell.rowIndex + 1;
			if (!rows[useIndex]) {
				rows[useIndex] = [];
			}
			rows[useIndex].push(cell);
			cell.rowIndex = useIndex;
		});

		rows.forEach((row, rowIndex) => {
			if (rowIndex < 2) {
				templateMetaData.header.push({
					rowIndex,
					cells: row.map((cell, index) => {
						return {
							content: cell.content,
							rowIndex,
							columnIndex: index,
							cellRef: cell,
						}
					})
				});
			} else {
				const cells: TemplateCellTuple[] = [];
				row.map((cell, index) => {
					if (index % 2) return;
					cells.push({
						tupleIndex: parseInt('' + Math.floor(index / 2)),
						cells: [{
							content: cell.content,
							rowIndex: rowIndex - 2,
							columnIndex: index,
							cellRef: cell,
						}, {
							content: row[index + 1].content,
							rowIndex: rowIndex - 2,
							columnIndex: index + 1,
							cellRef: row[index + 1],
						}]
					});
				});
				templateMetaData.rows.push({
					rowIndex: rowIndex - 2,
					cellTuples: cells,
				});
			}
		});
		setTemplateData(templateMetaData);
		logDebug('createTemplateMetaData', templateMetaData);
	}

	function createNewHeaderCell(rowIndex: number, columnIndex: number, content: string) {
		return {
			rowIndex,
			columnIndex,
			rowSpan: 1,
			columnSpan: 2,
			content,
			boundingRegions: [],
			spans: [],
		}
	}

	async function saveTemplateData(remote: boolean) {
		if (remote) {
			setFetching(v => v + 1);
			await RestAPI.fetchAPI('saveTemplateData', 'POST', { templateData });
			if (base64PDF) {
				await loadTestPDF(base64PDF);
			}
			setFetching(v => v - 1);
		} else {
			downloadFile(JSON.stringify(templateData), 'templateData.json');
			// window.localStorage.setItem('templateData', JSON.stringify(templateData));
		}
	}
	function onLocate(_tuple: TemplateCellTuple, meta: TemplateCellTupleMetadata) {
		const tuple = templateData?.rows[_tuple.cells[0].rowIndex].cellTuples[_tuple.tupleIndex];
		setSelectedTuple([_tuple.cells[0].rowIndex, _tuple.tupleIndex]);
		setTimeout(() => {
			setTestKeyData(meta.extracted?.extractedKeyInput?.acceptedTokens.join(' '));
			setTestValueData(meta.extracted?.extractedValueInput?.acceptedTokens.join(' '));
			setTestSelectedData(meta.extracted?.extractedSelectedWords?.acceptedTokens.join(' '));
			doEstimateTestWithValues(true, meta.extracted?.extractedKeyInput?.acceptedTokens.join(' '),
				meta.extracted?.extractedValueInput?.acceptedTokens.join(' '), meta.extracted?.extractedSelectedWords?.acceptedTokens.join(' '),
				tuple
			);
		}, 100);
	}

	function updateUI() {
		// logDebug('testCellInfo=', templateData?.rows[1].cellTuples[0].meta)
		const _templateData = { ...templateData! };
		const tuple = _templateData?.rows[selectedTuple[0]].cellTuples[selectedTuple[1]];
		if (tuple) {
			setWorkTuple(tuple);
			setWorkTupleId(tuple);
		}

		setTemplateData(_templateData);
	}

	function setIsCategory(tuple: TemplateCellTuple, isCategory: boolean) {
		templateData!.rows[tuple.cells[0].rowIndex].cellTuples[tuple.tupleIndex].meta = templateData!.rows[tuple.cells[0].rowIndex].cellTuples[tuple.tupleIndex].meta || {
		};
		templateData!.rows[tuple.cells[0].rowIndex].cellTuples[tuple.tupleIndex].meta!.isCategory = isCategory;
		updateUI();
	}

	function splitIgnoringBrackets(str: string) {
		// Match sequences inside parentheses or sequences of non-space characters outside of parentheses
		const regex = /\([^)]+\)|[^\s()]+/g;
		const matches = str.match(regex);

		// Filter out null results if no matches are found
		return matches ? matches : [];
	}


	function getTableFromTemplate() {
		if (!templateData) {
			return [];
		}
		const ret: (JSX.Element | JSX.Element[])[] = [];

		templateData!.header.forEach((row, rowIndex) => {
			ret.push(<tr key={rowIndex}>{row.cells.map((cell, index) => {
				return <td
					// colSpan={cell.columnSpan}
					key={index}
					style={{
						border: `1px solid black`,
					}}>
					{
						cell.content
					}
				</td>
			}
			)}</tr>);
		});

		templateData!.rows.forEach((row, rowIndex) => {
			if (false) {
			} else {
				ret.push(<tr key={rowIndex}>{
					row.cellTuples.map((tuple, index) => {
						return <td
							style={{
								padding: 0,
							}}
							key={index}>
							<table width="100%">
								<tbody>
									<tr
										onClick={() => setSelectedTuple([rowIndex, index])}
										onMouseEnter={() => setOverTuple([rowIndex, index])}
										onMouseLeave={() => setOverTuple([-1, -1])}
										style={{
											color: tuple.meta?.id ? 'black' : 'blue',
											cursor: 'pointer',
											backgroundColor: rowIndex === OverTuple[0] && index === OverTuple[1] ? 'yellow' : (rowIndex === selectedTuple[0] && index === selectedTuple[1]) ? 'green' : (tuple.meta?.isCategory ? 'lightblue' : 'white'),
											border: `1px solid gray`
										}}
									>
										<td
											style={{
												padding: 0,
												width: '50%',
												height: 60,
												border: `1px solid gray`
											}}
										>
											{tuple.cells[0].content}
										</td>
										<td
											style={{
												padding: 0,
												width: '50%',
												height: 60,
												border: `1px solid gray`
											}}>
											{tuple.cells[1].content}
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					}
					)}</tr>);

			}
		});
		return ret;
	}

	function getInteractiveCellData(content?: string): JSX.Element {
		if (!content) {
			return <div></div>;
		}
		const splitContent = splitIgnoringBrackets(content);

		return <div className='flex'>
			{
				splitContent.map(elm => <div className='mr-1' style={{
					cursor: 'pointer',
					backgroundColor: elm === overWord ? 'yellow' : 'white',

				}}
					onMouseEnter={() => setOverWord(elm)}
					onMouseLeave={() => setOverWord('')}
					onClick={() => {
						workTuple!.meta = workTuple!.meta || {};
						workTuple!.meta!.selecteables = workTuple?.meta?.selecteables || [];
						if (workTuple?.meta?.selecteables?.includes(elm)) {
							workTuple!.meta!.selecteables = workTuple!.meta!.selecteables.filter((x) => x !== elm);
						} else {
							workTuple?.meta?.selecteables?.push(elm);
						}
						updateUI();
					}}
				>
					{elm}
				</div>)
			}
		</div>;
	}

	function verifyEstimateIfNeeded() {
		if (!testKeyData && !testValueData && !testSelectedData) {
			return;
		}
		if (!workTuple) {
			return;
		}

		const errors: string[] = [];
		const testValues = Object.values(verificationMap).filter((v) => v && v.tupleId === workTuple.meta?.id) as IVerificationData[];
		logDebug('testValues', testValues)
		testValues.forEach((v) => {
			const _res = doEstimateTestWithValues(false, v.extracted.keyData, v.extracted.valueData, v.extracted.selectedData, workTuple);
			if (!_res.length) {
				logError('Failed to verify', v, workTuple);
				return;
			}
			_res.forEach((res, i) => {
				const curResults: IVerificationResults = {
					costOnP: (res.costOnP ?? 0)?.toFixed(2),
					estimate: (res.estimate ?? 0)?.toFixed(2),
					finalText: res.finalText ?? '',
					quantity: (res.quantity?.count ?? 0) + ' ' + (res.quantity?.measurement ?? ''),
					remove: (res.remove ?? 0)?.toFixed(2),
					replace: (res.replace ?? 0)?.toFixed(2),
					costTax: (res.costTax ?? 0)?.toFixed(2),
				}
				const testResults = (Array.isArray(v.results as any) ? v.results[i] : v.results) as IVerificationResults;
				if (curResults.costOnP !== testResults.costOnP ||
					curResults.estimate !== testResults.estimate ||
					curResults.finalText !== testResults.finalText ||
					curResults.quantity !== testResults.quantity ||
					curResults.remove !== testResults.remove ||
					curResults.replace !== testResults.replace ||
					curResults.costTax !== testResults.costTax) {
					logError('verification failed',
						curResults.finalText !== testResults.finalText ? 'finalText: ' + curResults.finalText + ' !== ' + testResults.finalText : '',
						curResults.quantity !== testResults.quantity ? 'quantity: ' + curResults.quantity + ' !== ' + testResults.quantity : '',
						curResults.remove !== testResults.remove ? 'remove: ' + curResults.remove + ' !== ' + testResults.remove : '',
						curResults.replace !== testResults.replace ? 'replace: ' + curResults.replace + ' !== ' + testResults.replace : '',
						curResults.costTax !== testResults.costTax ? 'costTax: ' + curResults.costTax + ' !== ' + testResults.costTax : '',
						curResults.costOnP !== testResults.costOnP ? 'costOnP: ' + curResults.costOnP + ' !== ' + testResults.costOnP : '',
						curResults.estimate !== testResults.estimate ? 'estimate: ' + curResults.estimate + ' !== ' + testResults.estimate : '',
					);
					const s = ('Verification Failed For ' + (v.extracted.keyData ? '(keys: ' + v.extracted.keyData + ')' : '') +
						' ' + (v.extracted.valueData ? '(vals: ' + v.extracted.valueData + ')' : '') +
						' ' + (v.extracted.selectedData ? '(selected: ' + v.extracted.selectedData + ')' : '') + '\n') +
						(curResults.finalText !== testResults.finalText ? 'finalText: ' + curResults.finalText + ' !== ' + testResults.finalText + '\n' : '') +
						(curResults.quantity !== testResults.quantity ? 'quantity: ' + curResults.quantity + ' !== ' + testResults.quantity + '\n' : '') +
						(curResults.remove !== testResults.remove ? 'remove: ' + curResults.remove + ' !== ' + testResults.remove + '\n' : '') +
						(curResults.replace !== testResults.replace ? 'replace: ' + curResults.replace + ' !== ' + testResults.replace + '\n' : '') +
						(curResults.costTax !== testResults.costTax ? 'costTax: ' + curResults.costTax + ' !== ' + testResults.costTax + '\n' : '') +
						(curResults.costOnP !== testResults.costOnP ? 'costOnP: ' + curResults.costOnP + ' !== ' + testResults.costOnP + '\n' : '') +
						(curResults.estimate !== testResults.estimate ? 'estimate: ' + curResults.estimate + ' !== ' + testResults.estimate : '');
					errors.push(s);
				} else {
					logDebug('verification passed', workTuple.meta?.id);
				}
			});
			setErrorList(errors);

		});

	}


	function setConstraints(value: string, isKey: boolean) {
		workTuple!.meta = workTuple!.meta || {};
		if (isKey) {
			workTuple!.meta.keyConstraints = value;
		} else {
			workTuple!.meta.valueConstraints = value;
		}
		updateUI();
	}
	function setEstimateMapping(value: string) {
		workTuple!.meta = workTuple!.meta || {};
		workTuple!.meta.estimateMapping = value;
		// logDebug('templateData', templateData)
		updateUI();
		verifyEstimateIfNeeded();
	}
	function setQuantityMapping(value: string) {
		workTuple!.meta = workTuple!.meta || {};
		workTuple!.meta.quantityMapping = value;
		// logDebug('templateData', templateData)
		updateUI();
		verifyEstimateIfNeeded();
	}
	function setPriceMapping(value: string) {
		workTuple!.meta = workTuple!.meta || {};
		workTuple!.meta.priceMapping = value;
		// logDebug('templateData', templateData)
		updateUI();
		verifyEstimateIfNeeded();
	}
	function setTax(value: string) {
		workTuple!.meta = workTuple!.meta || {};
		workTuple!.meta.tax = value;
		// logDebug('templateData', templateData)
		updateUI();
		verifyEstimateIfNeeded();
	}
	function setOnP(value: string) {
		workTuple!.meta = workTuple!.meta || {};
		workTuple!.meta.OnP = value;
		// logDebug('templateData', templateData)
		updateUI();
		verifyEstimateIfNeeded();
	}
	function setAcceptedValues(value: string, isKey: boolean) {
		workTuple!.meta = workTuple!.meta || {};
		if (isKey) {
			workTuple!.meta.keyAcceptedValues = value;
		} else {
			workTuple!.meta.valueAcceptedValues = value;
		}
		updateUI();
	}

	function downloadFile(textContent: string, filename: string) {
		// Step 1: Create new blob with content
		// const content = "Hello, this is your downloaded content!";
		const blob = new Blob([textContent], { type: "text/plain" });

		// Step 2: Create a new URL for the blob
		const url = URL.createObjectURL(blob);

		// Step 3-5: Create new anchor element and trigger download
		const a = document.createElement('a');
		a.href = url;
		a.download = filename;
		document.body.appendChild(a); // Required for Firefox
		a.click();

		// Step 6: Cleanup and revoke object URL
		setTimeout(() => {
			document.body.removeChild(a);
			URL.revokeObjectURL(url);
		}, 0);
	}

	function _getDefaultQuantityMapping() {
		const templateValue = templateData?.rows[selectedTuple[0]].cellTuples[selectedTuple[1]].cells[1].content.trim().toUpperCase();
		if (templateValue === 'SF') {
			return `^$=^$ SF`;
		} else if (templateValue === 'LF') {
			return `^$=^$ LF`;
		} else if (templateValue === 'EA') {
			return `^$=^$ EA`;
		} else if (templateValue === 'RL') {
			return `^$=^$ RL`;
		}
		return '';
	}
	function setWorkTupleId(tuple: TemplateCellTuple) {
		tuple.meta = tuple.meta || {};
		tuple.meta.quantityMapping = tuple.meta.quantityMapping || _getDefaultQuantityMapping();
		tuple.meta.tax = tuple.meta.tax || '7.95';
		tuple.meta.OnP = tuple.meta.OnP || '20';
		tuple.meta.priceMapping = tuple.meta.priceMapping || '^=';
		// if (!tuple.meta.selecteables) {
		// 	tuple.meta.selecteables = [];
		// 	// extract all data within brackets
		// 	const selecteables = tuple.cells[0].content.match(/\(.*\)/g);
		// 	logDebug('setWorkTupleId', selecteables);
		// 	selecteables?.forEach((selecteable) => {
		// 		tuple.meta!.selecteables!.push(selecteable);
		// 	});
		// }
		// if (tuple.meta?.id) {
		// 	return;
		// }
		if (tuple.meta?.isCategory) {
			tuple.meta.id = `Category_${tuple.cells[0].content}`;
			tuple.meta.id = tuple.meta.id.replace('&', 'and');
			// tuple.meta.id = tuple.meta.id.replace(/\(.*\)/, '');
			tuple.meta.id = tuple.meta.id.replaceAll('(', '').replaceAll(')', '');
			tuple.meta.id = tuple.meta.id.trim().replace(/\s/g, '_').toLowerCase();
			tuple.meta.id = tuple.meta.id.replace(/[^a-zA-Z0-9]/g, '_');
			tuple.meta.id = tuple.meta.id.replace(/_+/g, '_');
			return;
		}

		// find the first category above the current tuple
		for (let i = selectedTuple[0] || 0; i >= 0; i--) {
			if (templateData!.rows[i].cellTuples[selectedTuple[1]].meta?.isCategory) {
				let cat = templateData!.rows[i].cellTuples[selectedTuple[1]];
				tuple.meta.id = `${cat.cells[0].content} ${tuple.cells[0].content}`;
				tuple.meta.id = tuple.meta.id.replace('&', 'and');
				// tuple.meta.id = tuple.meta.id.replace(/\(.*\)/, '');
				tuple.meta.id = tuple.meta.id.replaceAll('(', '').replaceAll(')', '');
				tuple.meta.id = tuple.meta.id.trim().replace(/\s/g, '_').toLowerCase();
				tuple.meta.id = tuple.meta.id.replace(/[^a-zA-Z0-9]/g, '_');
				tuple.meta.id = tuple.meta.id.replace(/_+/g, '_');
				break;
			}
		}
	}

	async function loadTestPDF(_base64PDF: string) {
		setFetching(v => v + 1);
		const ret = await RestAPI.fetchAPI('createNewJob', 'POST', { base64Pdf: _base64PDF, processOnly: true }) as IJobResults;
		const filledTicSheetData = ret.pdfResults.flatMap((page) => page.filledTicSheetData!);
		const pages = ret.pdfResults.flatMap((page) => page.pages);
		setTestPdfId(ret.pdfResults[0].pdfID);
		setPdfData({
			pages,
			filledTicSheetData,
			processId: ret.processID,
		});
		setForceNewButton(forceNewButton + 1);
		setBase64PDF(_base64PDF);
		window.localStorage.setItem('base64PDF', _base64PDF);
		setFetching(v => v - 1);
	}
	function restoreFromJson(base64JSON: string) {
		const jsonStr = atob(base64JSON.replace('data:application/json;base64,', ''));
		const parsed = JSON.parse(jsonStr);
		setTemplateData(parsed);
		setForceNewButton(forceNewButton + 1);
	}

	function doEstimateTestWithValues(setResults: boolean, _testKeyData?: string, _testValueData?: string, _testSelectedData?: string, tuple?: TemplateCellTuple) {
		if ((tuple?.meta?.estimateMapping ?? '').trim() === '') {
			setTestEstimateResult([{ finalText: 'No mapping', }])
			return [];
		}

		if (!_testKeyData && !_testValueData && !_testSelectedData) {
			setTestEstimateResult([{ finalText: 'No Data', }])
			return [];
		}
		let keyData = (_testKeyData || '').split(/\s+/);
		let valueData = (_testValueData || '').split(/\s+/);
		let selectedData = (_testSelectedData || '').split(/\s+/);
		if (!tuple?.meta?.keyConstraints) {
			keyData = valueData;
		}
		const res = SharedUtils.processLineItemToEstimateLine(tuple!.meta!,
			keyData,
			valueData,
			selectedData
		)
		if (setResults) {
			setTestEstimateResult(res.length ? res : [{ finalText: 'No Result', }]);
		}
		return res;
	}
	function doEstimateTest() {
		doEstimateTestWithValues(true, testKeyData, testValueData, testSelectedData, workTuple);
	}
	return (
		<div>
			<div className='m-2' >
				<div className='flex flex-col'>
					<div className='flex'>
						<UploadFileButton onLoad={restoreFromJson} title="Load From Local" />
						<input style={{ cursor: 'pointer' }} className="m-2 bg-blue-100" type="button" value="Save Locally" onClick={() => saveTemplateData(false)} />
						<input style={{ cursor: 'pointer' }} className="m-2 bg-blue-100" type="button" value="Save To Server" onClick={() => saveTemplateData(true)} />
						<UploadFileButton key={forceNewButton} onLoad={loadTestPDF} title="Load Test PDF" />
					</div>
					<div style={{
						minHeight: 100,
					}}>
						{
							workTuple && <div className='flex'>
								<div className='border-2 border-gray-300'>
									<table >
										<tbody>
											<tr>
												<td style={{ border: '1px solid red', minWidth: 350 }}>{getInteractiveCellData(templateData?.rows[selectedTuple[0]].cellTuples[selectedTuple[1]].cells[0].content)}</td>
												<td style={{ border: '1px solid red', minWidth: 250 }}>{templateData?.rows[selectedTuple[0]].cellTuples[selectedTuple[1]].cells[1].content}</td>
											</tr>
										</tbody>
									</table>
									<div key={forceNewMetaDisplay}>
										<div className='flex m-1'>
											<input type='checkbox' checked={!!workTuple.meta?.isCategory} onChange={(e) => setIsCategory(workTuple, e.target.checked)} />
											<div>Is Category</div>
										</div>
										{
											!workTuple.meta?.isCategory && <div className='flex flex-col'>
												<div className='m-1 '>ID: <span style={{ color: 'red' }}>{workTuple.meta?.id}</span></div>
												<div className='m-1'>Selecteables: {JSON.stringify(workTuple.meta?.selecteables || [])}</div>
												<div className='m-1'>Key Constraints:
													<select
														style={{ width: 200, border: '1px solid black', padding: 2, margin: 2 }}
														value={workTuple.meta?.keyConstraints || 'None'} onChange={(e) => setConstraints(e.target.value, true)}>
														{
															Object.keys(constraintsTable).filter((key) => isNaN(Number(key))).map((key) => {
																return <option value={key}>{key}</option>
															})
														}
													</select>
												</div>
												{
													workTuple.meta?.keyConstraints === constraintsTable.AcceptedValues.toString() && <div>
														<textarea
															placeholder={
																`comma or new line delimited list.
^$ => Number. ^Y => Yes/No.
R^$ => anything that starts with R and continue with a number (like R234)
^R => W_C_F_PF_PC_DR_RR_REM  ^X => Size_S_M_L_XL`
															}
															style={{ width: 600, border: '1px solid black', padding: 2, margin: 2, height: 100 }}
															value={workTuple.meta?.keyAcceptedValues} onChange={(e) => setAcceptedValues(e.target.value, true)} />
													</div>

												}
												<div className='m-1'>Value Constraints :
													<select
														style={{ width: 200, border: '1px solid black', padding: 2, margin: 2 }}
														value={workTuple.meta?.valueConstraints || 'None'} onChange={(e) => setConstraints(e.target.value, false)}>
														{
															Object.keys(constraintsTable).filter((key) => isNaN(Number(key))).map((key) => {
																return <option value={key}>{key}</option>
															})
														}
													</select>
												</div>
												{
													workTuple.meta?.valueConstraints === constraintsTable.AcceptedValues.toString() && <div>
														<div>Accepted Values:  </div>
														<textarea
															placeholder={
																`comma or new line delimited list.
^$ => Number. ^Y => Yes/No.
R^$ => anything that starts with R and continue with a number (like R234)
^R => W_C_F_PF_PC_DR_RR_REM  ^X => Size_S_M_L_XL`
															}
															style={{ width: 600, border: '1px solid black', padding: 2, margin: 2, height: 100 }}
															value={workTuple.meta?.valueAcceptedValues} onChange={(e) => setAcceptedValues(e.target.value, false)} />
													</div>

												}
											</div>
										}
									</div>
								</div>
								<div key={forceNewMetaDisplay} className='border-2 border-gray-300'>
									<div>
										<strong>estimate line mapping:</strong>
									</div>
									<div>
										line text:
									</div>
									<textarea
										value={workTuple.meta?.estimateMapping} onChange={(e) => setEstimateMapping(e.target.value)}
										placeholder={
											`some prefix text here
^{
^X=^X room
^$=^$ hours
^X^$ | ^$^X=^$ ^X rooms
^}
some suffix text here
`
										}
										style={{ width: 600, border: '1px solid black', padding: 2, margin: 2, height: 150 }}
									/>
									<div className='flex'>
										<div>
											<div>quantity mapping:</div>
											<textarea
												value={workTuple.meta?.quantityMapping} onChange={(e) => setQuantityMapping(e.target.value)}
												placeholder={
													``
												}
												style={{ width: 150, border: '1px solid black', padding: 2, margin: 2, height: 50 }}
											/>
										</div>
										<div>
											<div>price mapping:</div>
											<textarea
												value={workTuple.meta?.priceMapping} onChange={(e) => setPriceMapping(e.target.value)}
												placeholder={
													`^=11.53`
												}
												style={{ width: 150, border: '1px solid black', padding: 2, margin: 2, height: 50 }}
											/>
										</div>
										<div>
											<div className='mt-6 ml-1 flex'>
												<div className='w-10'>Tax:</div>
												<input
													onChange={(e) => setTax(e.target.value)}
													type='text' placeholder='7.95' value={workTuple.meta?.tax} className='border-2 border-gray-300 mr-2 w-32' />
											</div>
											<div className='mt-1 ml-1 flex' >
												<div className='w-10'>O&P:</div>
												<input
													onChange={(e) => setOnP(e.target.value)}
													type='text' placeholder='7.95' value={workTuple.meta?.OnP} className='border-2 border-gray-300 mr-2 w-32' />
											</div>
										</div>
									</div>
									<div>
										Test:
										<br />
										Selected:<input type='text' placeholder='1/2' className='border-2 border-gray-300 mr-2 w-32' value={testSelectedData} onChange={
											(e) => setTestSelectedData(e.target.value)
										} />
										Key:<input type='text' placeholder='P2' className='border-2 border-gray-300 mr-2 w-32' value={testKeyData} onChange={
											(e) => setTestKeyData(e.target.value)
										} />
										Value:<input type='text' placeholder='126' className='border-2 border-gray-300 w-32' value={testValueData} onChange={
											(e) => setTestValueData(e.target.value)
										} />

										<button className='bg-blue-100 ml-2' onClick={doEstimateTest}>Test</button>
										<br />
										<div className='flex'>
											<table className='border-2 border-gray-300 m-1'>
												<tbody>
													<tr>
														<td className='border-2 border-gray-300'>
															Description
														</td>
														<td className='border-2 border-gray-300'>
															Quantity
														</td>
														<td className='border-2 border-gray-300'>
															Remove
														</td>
														<td className='border-2 border-gray-300'>
															Replace
														</td>
														<td className='border-2 border-gray-300'>
															Tax
														</td>
														<td className='border-2 border-gray-300'>
															O&P
														</td>
														<td className='border-2 border-gray-300'>
															Total
														</td>
													</tr>
													{
														testEstimateResult?.map((_testEstimateResult, i) => {
															return <tr>
																<td className='border-2 border-gray-300 max-w-[220px]'>
																	{_testEstimateResult?.finalText}
																</td>
																<td className='border-2 border-gray-300'>
																	{_testEstimateResult?.quantity && `${_testEstimateResult?.quantity!.count} ${_testEstimateResult?.quantity!.measurement}`.toUpperCase()}
																</td>
																<td className='border-2 border-gray-300'>
																	{

																		((_testEstimateResult?.remove ?? 0)).toFixed(2)
																	}
																</td>
																<td className='border-2 border-gray-300'>
																	{
																		((_testEstimateResult?.replace ?? 0)).toFixed(2)
																	}
																</td>
																<td className='border-2 border-gray-300'>
																	{
																		(_testEstimateResult?.costTax ?? 0).toFixed(2)
																	}
																</td>
																<td className='border-2 border-gray-300'>
																	{
																		(_testEstimateResult?.costOnP ?? 0).toFixed(2)
																	}
																</td>
																<td className='border-2 border-gray-300'>
																	{
																		(_testEstimateResult?.estimate ?? 0).toFixed(2)
																	}
																</td>
															</tr>
														})
													}
												</tbody>
											</table>
											<div className='flex items-center'>
												<input className='ml-1' type='checkbox' checked={!!verificationMap[verificationKey ?? '']} onChange={async (e) => {
													if (!verificationKey) return;
													setFetching(v => v + 1);
													const verifiedData: IVerificationData = {
														verified: e.target.checked,
														verificationKey,
														pdfId: testPdfId ?? '',
														page,
														tupleId: workTuple.meta?.id ?? '',
														extracted: {
															keyData: testKeyData ?? '',
															valueData: testValueData ?? '',
															selectedData: testSelectedData ?? '',
														},
														results: testEstimateResult?.map((_testEstimateResult) => {
															return {
																finalText: _testEstimateResult?.finalText ?? '',
																quantity: _testEstimateResult?.quantity ? `${_testEstimateResult?.quantity!.count} ${_testEstimateResult?.quantity!.measurement}` : '',
																remove: (_testEstimateResult?.remove || 0).toFixed(2),
																replace: (_testEstimateResult?.replace || 0).toFixed(2),
																costTax: (_testEstimateResult?.costTax ?? 0).toFixed(2),
																costOnP: (_testEstimateResult?.costOnP ?? 0).toFixed(2),
																estimate: (_testEstimateResult?.estimate || 0).toFixed(2),
															}
														}) ?? [],
													}
													logDebug(JSON.stringify(verifiedData));
													if (e.target.checked) {
														setVerificationMap({ ...verificationMap, [verificationKey!]: verifiedData })
													} else {
														setVerificationMap({ ...verificationMap, [verificationKey!]: false })
													}
													await RestAPI.fetchAPI('verifyEstimate', 'POST', {
														verifiedData
													});
													setFetching(v => v - 1);
												}} />
												<div className='ml-1'>Verified</div>
											</div>
										</div>
									</div>
								</div>
								<div className='flex flex-col border border-black w-[100%]'>
									<div className='h-[100%]'>
										{
											errorList.length === 0 ? <div className='text-green-700'>verification passed</div>
												:
												<div className='text-red-700'>
													{
														errorList.map((error, i) => <div className='m-2 mb-4' key={i}>{
															error.split('\n').map((str, index, array) =>
																index === array.length - 1 ? str : <>{str}<br /></>
															)
														}</div>)
													}
												</div>
										}
									</div>
									<button className='bg-blue-100' onClick={
										async () => {
											const areYouSure = window.confirm('Are you sure you want to reset all verifications?');
											if (areYouSure) {
												setFetching(v => v + 1);
												const keys = Object.keys(verificationMap);
												for (let i = 0; i < keys.length; i++) {
													const verifiedData = verificationMap[keys[i]];
													if (verifiedData && verifiedData.verified && verifiedData.tupleId === workTuple.meta?.id) {
														verifiedData.verified = false;
														setVerificationMap({ ...verificationMap, [keys[i]]: false });
														await RestAPI.fetchAPI('verifyEstimate', 'POST', {
															verifiedData
														});
													}
												}
												setFetching(v => v - 1);
											}
										}
									} >Reset Verifications</button>
								</div>
							</div>
						}
					</div>
				</div>
				<div className='flex'>
					{
						<table >
							{
								getTableFromTemplate().map((content, i) => <tbody key={i}>{content}</tbody>)
							}
						</table>
					}
					{
						pdfData && <div>
							<LassoFilledLinesBrowser onPage={(page) => setPage(page)} pages={pdfData.pages} filledTicSheetData={pdfData.filledTicSheetData} processID={pdfData.processId} onLocate={onLocate} />
						</div>
					}
				</div>
			</div>
			{fetching && <LoadingAnimation />}
		</div>
	);
}

export default EditTemplatePage;
