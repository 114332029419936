import { logDebug } from './logger';

export default class JaroWinklerDistance {
	private prefixScaleFactor = 0.1;

	private jaroDistance(s1: string, s2: string): number {
		const windowSize = Math.floor(Math.max(s1.length, s2.length) / 2) - 1;
		const s1Matches = this.getMatchingCharacters(s1, s2, windowSize);
		const s2Matches = this.getMatchingCharacters(s2, s1, windowSize);
		const numMatches = s1Matches.length;
		let numTranspositions = 0;
		for (let i = 0; i < numMatches; i++) {
			if (s1Matches[i] !== s2Matches[i]) {
				numTranspositions++;
			}
		}
		numTranspositions /= 2;
		if (numMatches === 0) {
			return 0;
		}

		return (numMatches / s1.length + numMatches / s2.length + (numMatches - numTranspositions) / numMatches) / 3;
	}

	private commonPrefixLength(s1: string, s2: string, maxPrefixLength: number): number {
		let prefixLength = 0;
		while (prefixLength < maxPrefixLength && prefixLength < s1.length && prefixLength < s2.length && s1.charAt(prefixLength) === s2.charAt(prefixLength)) {
			prefixLength++;
		}
		return prefixLength;
	}

	private getMatchingCharacters(s1: string, s2: string, windowSize: number): string[] {
		const matches: boolean[] = new Array(s1.length).fill(false);
		for (let i = 0; i < s1.length; i++) {
			const windowStart = Math.max(0, i - windowSize);
			const windowEnd = Math.min(i + windowSize + 1, s2.length);
			for (let j = windowStart; j < windowEnd; j++) {
				if (!matches[j] && s1.charAt(i) === s2.charAt(j)) {
					matches[j] = true;
					break;
				}
			}
		}
		const matchingCharacters: string[] = [];
		for (let i = 0; i < s1.length; i++) {
			if (matches[i]) {
				matchingCharacters.push(s1.charAt(i));
			}
		}
		return matchingCharacters;
	}

	public distance(s1: string, s2: string): number {
		const jaroDistance = this.jaroDistance(s1, s2);
		const prefixLength = this.commonPrefixLength(s1, s2, 4);
		const dist = jaroDistance + (prefixLength * this.prefixScaleFactor * (1 - jaroDistance));
		// logDebug('jaroDistance', jaroDistance, 'prefixLength', prefixLength, 'dist', dist);
		return dist;
	}


	public similarity(s1: string, s2: string): number {
		const s1Words = s1.split(/[\s,]+/);
		const s2Words = s2.split(/[\s,]+/);
		let totalSimilarity = 0;
		for (const s1Word of s1Words) {
			// find highest similarity for each word in s1
			let highestSimilarity = 0;
			for (const s2Word of s2Words) {
				const similarity = this.distance(s1Word, s2Word);
				if (similarity > highestSimilarity) {
					highestSimilarity = similarity;
				}
			}
			totalSimilarity += highestSimilarity;
		}
		return totalSimilarity / (s1Words.length || 1);
	}
}
