import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import EditTemplatePage from './pages/EditTemplatePage';
import ProcessPDFPage from './pages/ProcessPDFPage';
import LassoPage from './pages/LassoPage';
import LassoJobs from './pages/LassoJobs';
import { observer } from 'mobx-react';
import { getUserStore } from './data/DataStores';
import { useNavigate, useLocation } from 'react-router-dom';
import FilledTicSheetPage from './pages/FilledTicSheetPage';

const NotFoundPage: React.FC = () => {
	return (
		<div>
			<h1>404 Not Found</h1>
		</div>
	);
};
const App: React.FC = observer(() => {

	const userStore = useRef(getUserStore());
	if (!userStore.current.appReady) {
		return <div>Loading...</div>;
	}
	return (
		<Router>
			<Routes>
				<Route path="/lasso" element={<LassoPage />} />
				<Route path="/jobs" element={<LassoJobs />} />
				<Route path="/template" element={<EditTemplatePage />} />
				<Route path="/filled" element={<FilledTicSheetPage />} />
				{/* <Route path="/process" element={<ProcessPDFPage />} /> */}
				<Route path="*" element={<LassoPage />} />
			</Routes>
		</Router>
	);
});

export default App;

