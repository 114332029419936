import React, { useEffect, useRef, useState } from 'react';
import RestAPI from '../utils/restAPI';

import LoadingAnimation from '../components/ui/utils/LoadingAnimation';
import { logDebug } from '../shared/logger';
import { LineType, AzureFormResult, Cell, SelectionMark, TemplateCellTuple, TemplateMetaData, constraintsTable, TemplateCellTupleMetadata } from '../shared/Azure';
import UploadFileButton from '../components/utils/UploadFileButton';
import { useSearchParams } from 'react-router-dom';
import { getEstimateStore } from '../data/DataStores';
import { observer } from 'mobx-react';



const FilledTicSheetPage: React.FC = observer(() => {
	const estimateStore = useRef(getEstimateStore());
	const [params] = useSearchParams();
	const [fetching, setFetching] = useState<boolean>(false);
	const [OverTuple, setOverTuple] = useState<[number, number]>([-1, -1]);
	const [selectedTuple, setSelectedTuple] = useState<[number, number]>([-1, -1]);
	const [workTuple, setWorkTuple] = useState<TemplateCellTuple>();
	const [overWord, setOverWord] = useState<string>('');
	const [forceNewMetaDisplay, setForceNewMetaDisplay] = useState<number>(0);
	const processID = params.get('processID');


	useEffect(() => {
		loadTemplateData();
	}, []);

	const loadTemplateData = async (remote = true) => {
		async function fetchSheetData() {
			if (processID) {
				const ret = await RestAPI.fetchAPI('getJob', 'GET', { jobId: processID });
				estimateStore.current.setRawSheetData(ret);
				// setSheetData(ret);
			}
		}

		if (!remote) {
			return;
		}
		if (fetching || estimateStore.current.templateMetaData) {
			return;
		}
		try {

			setFetching(true);
			await fetchSheetData();
			const ret = await RestAPI.fetchAPI('loadTemplateData', 'GET');
			estimateStore.current.setTemplateMetaData(ret);
			logDebug('******filledTicSheetData***', estimateStore.current.filledTicSheetData)
		} catch (error) {
		}
		setFetching(false);
	}



	function getTableFromTemplate() {
		if (!estimateStore.current.templateMetaData) {
			return [];
		}
		const ret: (JSX.Element | JSX.Element[])[] = [];

		estimateStore.current.templateMetaData.header.forEach((row, rowIndex) => {
			ret.push(<tr key={rowIndex}>{row.cells.map((cell, index) => {
				return <td
					// colSpan={cell.columnSpan}
					key={index}
					style={{
						border: `1px solid black`,
					}}>
					{
						cell.content
					}
				</td>
			}
			)}</tr>);
		});

		function findTupleInSheetData(tuple: TemplateCellTuple): any {
			const _cell0 = tuple.cells[0];
			if (!estimateStore.current.rawSheetData.length) return null;
			let ret: any = null;
			estimateStore.current.rawSheetData[0].lineItems.some((lineItem, index) => {
				if (!lineItem.matchCell) return;
				const cell0 = lineItem.matchCell.templateTuple.cells[0];
				if (cell0.content === _cell0.content && cell0.rowIndex === _cell0.rowIndex && cell0.columnIndex === _cell0.columnIndex) {
					ret = {
						meta: lineItem.matchCell.templateTuple.meta,
						selectedWords: lineItem.matchCell.azureCells[0].selectedWords
					};

					// logDebug('---meta---', cell0.content, lineItem.matchCell.templateTuple.meta)
				}
				return ret;
			});
			return ret;
		}

		estimateStore.current.templateMetaData!.rows.forEach((row, rowIndex) => {
			if (false) {
			} else {
				ret.push(<tr key={rowIndex}>{
					row.cellTuples.map((tuple, index) => {
						const metaAndSelect = findTupleInSheetData(tuple);
						const meta = metaAndSelect?.meta as TemplateCellTupleMetadata;
						const selectedWords = metaAndSelect?.selectedWords as SelectionMark[];
						return <td
							style={{
								padding: 0,
							}}
							key={index}>
							<table width="100%">
								<tbody>
									<tr
										style={{
											color: tuple.meta?.id ? 'black' : 'blue',
											// cursor: 'pointer',
											backgroundColor: rowIndex === OverTuple[0] && index === OverTuple[1] ? 'yellow' : (rowIndex === selectedTuple[0] && index === selectedTuple[1]) ? 'green' : (tuple.meta?.isCategory ? 'lightblue' : 'white'),
											border: `1px solid gray`
										}}
									>
										<td
											style={{
												padding: 0,
												width: '50%',
												height: 60,
												border: `1px solid gray`
											}}
										>
											{tuple.cells[0].content}
											<span className='bg-green-100 ml-4'>
												{
													meta?.extracted?.extractedKeyInput.acceptedTokens.join(' ')
												}
											</span>
											<span className='bg-red-100 ml-4'>
												{
													meta?.extracted?.extractedKeyInput.rejectedTokens.join(' ')
												}
											</span>
											<br />
											{
												selectedWords?.map((word, index) => <span key={index} className='bg-yellow-100 ml-4'>{word.content}</span>)
											}
										</td>
										<td
											style={{
												padding: 0,
												width: '50%',
												height: 60,
												border: `1px solid gray`
											}}>
											{tuple.cells[1].content}
											<span className='bg-green-100 ml-4'>
												{
													meta?.extracted?.extractedValueInput.acceptedTokens.join(' ')
												}
											</span>
											<span className='bg-red-100 ml-4'>
												{
													meta?.extracted?.extractedValueInput.rejectedTokens.join(' ')
												}
											</span>

										</td>
									</tr>
								</tbody>
							</table>
						</td>
					}
					)}</tr>);

			}
		});
		return ret;
	}



	return (
		<div>
			<div className='m-2' >
				<div>
					{
						<table >
							{
								getTableFromTemplate().map((content, index) => <tbody key={index}>{content}</tbody>)
							}
						</table>
					}
				</div>
			</div>
			{fetching && <LoadingAnimation />}
		</div>
	);
});

export default FilledTicSheetPage;
