import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { debugStyle, getEstimateStore } from '../../../data/DataStores';
import RestAPI from '../../../utils/restAPI';
import { logDebug } from '../../../shared/logger';

const LassoImagesPanel: React.FC = observer(() => {
	const estimateStore = useRef(getEstimateStore());
	const [largeImage, setLargeImage] = useState<string>('');
	// logDebug('estimateStore.current.allFiles', estimateStore.current.allFiles);
	return <div className={debugStyle('ml-[73px] mr-[73px]', 'bg-blue-100')}>
		<div className='flex' onClick={() => estimateStore.current.setCurrentView('Estimate')} >
			<img src='/assets/ui/arrow-left.svg' className='ml-4 mt-4 cursor-pointer' />
			<div className='ml-2 mt-4 cursor-pointer'>Back to line item</div>
		</div>
		<div>
			{
				estimateStore.current.allFiles.length && <div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'center',
						gap: '4px'
					}}>
					{
						estimateStore.current.allFiles.map((file, index) => {
							if (!(file.mimetype.toLowerCase().endsWith('png') || file.mimetype.toLowerCase().endsWith('jpg') || file.mimetype.toLowerCase().endsWith('jpeg'))) {
								return null;
							}
							return <>
								{
									file.mimetype.toLowerCase().endsWith('png') || file.mimetype.toLowerCase().endsWith('jpg') || file.mimetype.toLowerCase().endsWith('jpeg') &&
									<div className='cursor-pointer'
										onClick={() => {
											setLargeImage(RestAPI.getServerBaseUrl(file.localPath!));
										}}
									>
										{/* <div className='mt-8'>{file.originalname}</div> */}
										<img src={RestAPI.getServerBaseUrl(file.localPath!)} className='m-4 w-32' />
									</div>
								}

							</>
						})
					}
				</div>
			}
			<div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center' style={{ display: largeImage ? 'flex' : 'none' }} onClick={() => setLargeImage('')}>
				<img src={largeImage} className='h-[80vh] ' />
			</div>
		</div>
	</div >
});

export default LassoImagesPanel;