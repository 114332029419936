import { useEffect, useRef, useState } from 'react';
import { getEstimateStore } from '../../../data/DataStores';
import RestAPI from '../../../utils/restAPI';
import LassoFilledLinesBrowser from './LassoFilledLinesBrowser';

const LassoConflictsWizard: React.FC<{ onDone: () => void }> = ({ onDone }) => {
	const estimateStore = useRef(getEstimateStore());

	return <div style={{
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		zIndex: 1000,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}}>
		<LassoFilledLinesBrowser processID={estimateStore.current.processID} pages={estimateStore.current.pages} filledTicSheetData={estimateStore.current.filledTicSheetData}
			onDone={onDone} />
	</div>

}

export default LassoConflictsWizard;