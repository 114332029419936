import React from 'react';

const ImageCropping: React.FC<{
	url: string, rect: {
		x: number,
		y: number,
		width: number,
		height: number
	}
}> = ({ url, rect }) => {
	// Destructure the rect object to extract x, y, width, and height
	const { x, y, width, height } = rect;

	// Define inline styles for the cropping container
	const imageStyle = {
		width: `${width}px`, // Use the width from the rect
		height: `${height}px`, // Use the height from the rect
		backgroundImage: `url(${url})`, // Set the image URL
		// backgroundSize: 'cover', // Adjust this value as needed to fit the image properly
		backgroundPosition: `-${x}px -${y}px`, // Shift the image based on x and y from the rect
		backgroundRepeat: 'no-repeat'
	};

	return (
		<div style={imageStyle}>
			{/* The specified area of the image will be displayed here */}
		</div>
	);
}

export default ImageCropping;
