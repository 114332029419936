import { useEffect, useRef, useState } from 'react';
import { getEstimateStore } from '../../../data/DataStores';
import RestAPI from '../../../utils/restAPI';
import ImageCropping from '../utils/ImageCropping';
import { IFilledTicSheetData, LineType, TemplateCellTuple, TemplateCellTupleMetadata } from '../../../shared/Azure';
import { logDebug } from '../../../shared/logger';
import SharedUtils from '../../../shared/SharedUtils';


interface IFilledAndPage {
	filledTicSheetData: IFilledTicSheetData;
	page: number,
	index: number
}
const LassoFilledLinesBrowser: React.FC<{
	processID: string, pages: LineType.Page[],
	filledTicSheetData: IFilledTicSheetData[][], onDone?: () => void,
	onLocate?: (tuple: TemplateCellTuple, meta: TemplateCellTupleMetadata) => void, onPage?: (page: number) => void
}> = ({ onLocate, onDone, filledTicSheetData, pages, processID, onPage }) => {
	const estimateStore = useRef(getEstimateStore());
	const [index, setIndex] = useState<number>(0);
	const [rect, setRect] = useState<{ x: number, y: number, width: number, height: number }>({ x: 0, y: 0, width: 0, height: 0 });
	const [page, setPage1] = useState<number>(0);
	const [pageURL, setPageURL] = useState<string>('');
	const [key, setKey] = useState<string>('');
	const [value, setValue] = useState<string>('');
	const [origKey, setOrigKey] = useState<string>('');
	const [origValue, setOrigValue] = useState<string>('');
	const [workFilledTicSheetData, setWorkFilledTicSheetData] = useState<IFilledAndPage[]>([]);

	// logDebug('filledTicSheetData', filledTicSheetData);

	useEffect(() => {
		const _filledTicSheetData: IFilledAndPage[] = [];
		if (!onLocate) {
			for (let i = 0; i < filledTicSheetData.length; i++) {
				const temp = filledTicSheetData[i].filter((item) => {
					const ret = (item.meta?.extracted?.extractedKeyInput.rejectedTokens.length || 0) > 0
						|| (item.meta?.extracted?.extractedValueInput.rejectedTokens.length || 0) > 0;
					return ret;
				});
				const allForPage = temp.map((item, index) => {
					return { filledTicSheetData: item, page: i, index };
				});
				_filledTicSheetData.push(...allForPage);
			}
		} else {
			for (let i = 0; i < filledTicSheetData.length; i++) {
				const allForPage = filledTicSheetData[i].map((item, index) => {
					return { filledTicSheetData: item, page: i, index };
				});
				_filledTicSheetData.push(...allForPage);
			}
		}
		setWorkFilledTicSheetData(_filledTicSheetData);
		if (_filledTicSheetData.length == 0) {
			onDone && onDone();
		}
	}, []);


	function setPage(page: number) {
		onPage && onPage(page);
		setPage1(page);
	}

	useEffect(() => {
		setPageURL(RestAPI.getServerBaseUrl(pages[page].pngFile!));
	}, [page]);

	// logDebug('filledTicSheetData', filledTicSheetData);
	useEffect(() => {
		if (!workFilledTicSheetData.length) return;

		const cell0 = workFilledTicSheetData[index].filledTicSheetData.azureCells[0];
		const cell1 = workFilledTicSheetData[index].filledTicSheetData.azureCells[1];
		const minX = Math.min(cell0.boundingRegions[0].polygon[0].x, cell1.boundingRegions[0].polygon[0].x);
		const minY = Math.min(cell0.boundingRegions[0].polygon[0].y, cell1.boundingRegions[0].polygon[0].y);
		const maxX = Math.max(cell0.boundingRegions[0].polygon[2].x, cell1.boundingRegions[0].polygon[2].x);
		const maxY = Math.max(cell0.boundingRegions[0].polygon[2].y, cell1.boundingRegions[0].polygon[2].y);

		// TODO :: check why we need to add 10 to the height
		setRect({
			x: minX - 10,
			y: minY - 10,
			width: maxX - minX,
			height: maxY - minY + 20
		});
		const acceptedKeys = workFilledTicSheetData[index].filledTicSheetData.meta?.extracted?.extractedKeyInput.acceptedTokens.join(' ').trim();
		const rejectedKeys = workFilledTicSheetData[index].filledTicSheetData.meta?.extracted?.extractedKeyInput.rejectedTokens.join(' ').trim();
		const acceptedValues = workFilledTicSheetData[index].filledTicSheetData.meta?.extracted?.extractedValueInput.acceptedTokens.join(' ').trim();
		const rejectedValues = workFilledTicSheetData[index].filledTicSheetData.meta?.extracted?.extractedValueInput.rejectedTokens.join(' ').trim();
		let acceptedSelect = workFilledTicSheetData[index].filledTicSheetData.meta?.extracted?.extractedSelectedWords.acceptedTokens.join(' ').trim();
		let rejectedSelect = workFilledTicSheetData[index].filledTicSheetData.meta?.extracted?.extractedSelectedWords.rejectedTokens.join(' ').trim();
		acceptedSelect = acceptedSelect?.replaceAll('(', '').replaceAll(')', '');
		setKey(`${acceptedSelect} ${acceptedKeys} ${rejectedKeys}`);
		setValue(`${acceptedValues} ${rejectedValues}`);
		setOrigKey(`${acceptedSelect} ${acceptedKeys} ${rejectedKeys}`);
		setOrigValue(`${acceptedValues} ${rejectedValues}`);
	}, [index, page, workFilledTicSheetData]);

	// if (acceptedSelect?.length) {
	// 	acceptedSelect = 'S[' + acceptedSelect + ']';
	// }
	// if (rejectedSelect?.length) {
	// 	rejectedSelect = 'S[' + rejectedSelect + ']';
	// }
	// logDebug('filledTicSheetData', page, index, workFilledTicSheetData);
	if (!workFilledTicSheetData.length) {
		return <div>loading</div>
	}
	return <div>

		<div className='bg-white relative border-2 border-black'>
			{!!onLocate && <div>Page : {page + 1}, index : {workFilledTicSheetData[index].index + 1}</div>}
			{
				!onLocate && <div>
					<div className="select-none w-[688px] h-24 px-6 pt-6 bg-white justify-start items-start gap-4 inline-flex">
						<div className="w-12 h-12 p-3 bg-blue-400 rounded-[10px] shadow border border-gray-200 justify-center items-center flex">
							<img src="/assets/ui/alert-circle-2.svg" alt="alert" />
						</div>

						<div className="select-none grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
							<div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">
								Confirm value found in tick sheet
							</div>
							<div className="self-stretch text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
								We're not sure about a line in the Tick Sheet. Please confirm the correct note
							</div>
						</div>
						<div className="select-none">
							<div onClick={onDone}
								className="cursor-pointer w-12 h-12 p-3 bg-white rounded-[10px] shadow border border-gray-200 justify-center items-center flex">
								<img src="/assets/ui/x.svg" alt="close" />
							</div>
						</div>
					</div>
				</div>

			}
			<div className='flex justify-center items-start mt-10 '>
				<div className='w-[95%]'>
					<div className='flex border border-black justify-center items-center  h-32 w-[100%]'>
						<ImageCropping url={pageURL} rect={rect} />
					</div>
					<table className='w-[100%] mt-2'>
						<tbody className='w-[100%]'>
							<tr className='w-[100%]'>
								<td className='w-[50%] border border-black'>
									{
										workFilledTicSheetData[index].filledTicSheetData.tuple.cells[0].content.replaceAll('ÃÂÃÂ', 'x').replaceAll('ÃÂÃÂÃÂÃÂ', 'x')
									}
								</td>
								<td className='w-[50%] border border-black'>
									{
										workFilledTicSheetData[index].filledTicSheetData.tuple.cells[1].content
									}
								</td>
							</tr>
							<tr className='w-[100%]'>
								<td className='w-[50%] border border-black'>
									<div className='flex w-[100%] '>
										<input className='border border-black w-[100%]  p-1' type='text' value={key.trim()} onChange={(e) => setKey(e.target.value)} onBlur={() => {
											if (key.toLowerCase().replace(/\s+/g, ' ').trim() !== origKey.toLowerCase().replace(/\s+/g, ' ')) {
												let _key = key.toLowerCase().replace(/\s+/g, ' ').trim();
												const meta = workFilledTicSheetData[index].filledTicSheetData.meta!;
												const selecteables = meta.selecteables?.map((item) => item.toLowerCase().replaceAll('(', '').replaceAll(')', '')) || [];
												const acceptedSelect: string[] = [];
												for (let i = 0; i < selecteables.length; i++) {
													if (_key.includes(`${selecteables[i]} `) || _key.endsWith(selecteables[i])) {
														_key = _key.replaceAll(selecteables[i], '').trim();
														acceptedSelect.push(selecteables[i]);
													}
												}
												const keys = _key.split(/\s+/);
												workFilledTicSheetData[index].filledTicSheetData.meta!.extracted!.extractedKeyInput.rejectedTokens = [];
												workFilledTicSheetData[index].filledTicSheetData.meta!.extracted!.extractedKeyInput.acceptedTokens = keys;
												workFilledTicSheetData[index].filledTicSheetData.meta!.extracted!.extractedSelectedWords.rejectedTokens = [];
												workFilledTicSheetData[index].filledTicSheetData.meta!.extracted!.extractedSelectedWords.acceptedTokens = acceptedSelect;
												if (!onLocate) {
													const lineIndex = estimateStore.current.pages[workFilledTicSheetData[index].page].lineItems.findIndex((item) => item.matchCell?.templateTuple.meta.id === workFilledTicSheetData[index].filledTicSheetData.tuple.meta?.id);
													if (lineIndex > -1) {
														estimateStore.current.updateLineItemExtracted(workFilledTicSheetData[index].page, lineIndex, SharedUtils.clone(workFilledTicSheetData[index].filledTicSheetData.meta!.extracted!.extractedKeyInput), undefined, SharedUtils.clone(workFilledTicSheetData[index].filledTicSheetData.meta!.extracted!.extractedSelectedWords));
														// estimateStore.current.pages[index].lineItems[realLine2].matchCell!.templateTuple.meta!.extracted!.extractedKeyInput = SharedUtils.clone(workFilledTicSheetData[index].filledTicSheetData.meta!.extracted!.extractedKeyInput);
														// estimateStore.current.pages[index].lineItems[realLine2].matchCell!.templateTuple.meta!.extracted!.extractedSelectedWords = SharedUtils.clone(workFilledTicSheetData[index].filledTicSheetData.meta!.extracted!.extractedSelectedWords);
													}
												}
												// const realLine = estimateStore.current.filledTicSheetData[workFilledTicSheetData[index].page].findIndex((item) => item.tuple.meta?.id === workFilledTicSheetData[index].filledTicSheetData.tuple.meta?.id);
												// if (realLine > -1) {
												// 	estimateStore.current.filledTicSheetData[workFilledTicSheetData[index].page][realLine].meta!.extracted!.extractedKeyInput = SharedUtils.clone(workFilledTicSheetData[index].filledTicSheetData.meta!.extracted!.extractedKeyInput);
												// 	estimateStore.current.filledTicSheetData[workFilledTicSheetData[index].page][realLine].meta!.extracted!.extractedSelectedWords = SharedUtils.clone(workFilledTicSheetData[index].filledTicSheetData.meta!.extracted!.extractedSelectedWords);
												// }
											}
										}} />
									</div>
								</td>
								<td className='w-[50%] border border-black'>
									<div className='flex w-[100%] '>
										<input className='border border-black w-[100%] p-1' type='text' value={value.trim()} onChange={(e) => setValue(e.target.value)} onBlur={() => {
											if (value.toLowerCase().replace(/\s+/g, ' ').trim() !== origValue.toLowerCase().replace(/\s+/g, ' ')) {
												let _value = value.toLowerCase().replace(/\s+/g, ' ').trim();
												workFilledTicSheetData[index].filledTicSheetData.meta!.extracted!.extractedValueInput.rejectedTokens = [];
												workFilledTicSheetData[index].filledTicSheetData.meta!.extracted!.extractedValueInput.acceptedTokens = _value.split(/\s+/);
												if (!onLocate) {
													const lineIndex = estimateStore.current.pages[workFilledTicSheetData[index].page].lineItems.findIndex((item) => item.matchCell?.templateTuple.meta.id === workFilledTicSheetData[index].filledTicSheetData.tuple.meta?.id);
													if (lineIndex > -1) {
														estimateStore.current.updateLineItemExtracted(workFilledTicSheetData[index].page, lineIndex, undefined, SharedUtils.clone(workFilledTicSheetData[index].filledTicSheetData.meta!.extracted!.extractedValueInput), undefined);
														// estimateStore.current.pages[index].lineItems[realLine2].matchCell!.templateTuple.meta!.extracted!.extractedValueInput = SharedUtils.clone(workFilledTicSheetData[index].filledTicSheetData.meta!.extracted!.extractedValueInput);
													}
												}

												// const realLine = estimateStore.current.filledTicSheetData[workFilledTicSheetData[index].page].findIndex((item) => item.tuple.meta?.id === workFilledTicSheetData[index].filledTicSheetData.tuple.meta?.id);
												// if (realLine > -1) {
												// 	estimateStore.current.filledTicSheetData[workFilledTicSheetData[index].page][realLine].meta!.extracted!.extractedValueInput = SharedUtils.clone(workFilledTicSheetData[index].filledTicSheetData.meta!.extracted!.extractedValueInput);
												// }
											}
										}} />
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div>
				<div className="w-[688px] mt-4 flex-col justify-start items-start inline-flex">
					<div className="self-stretch px-6 pb-6 justify-start items-start gap-3 inline-flex">
						{
							!!onLocate &&
							<div
								onClick={() => onLocate(workFilledTicSheetData[index].filledTicSheetData.tuple, workFilledTicSheetData[index].filledTicSheetData.meta)}
								className="select-none cursor-pointer grow shrink basis-0 h-11 px-[18px] py-2.5 bg-white rounded-lg border border-gray-200 justify-center items-center gap-2 flex">
								<div
									className="text-slate-700 text-base font-semibold font-['Inter'] leading-normal">
									Locate
								</div>
							</div>
						}
						<div
							onClick={() => {
								const nextIndex = index > 0 ? index - 1 : 0;
								setPage(workFilledTicSheetData[nextIndex].page);
								setIndex(nextIndex);
							}}
							className={[index > 0 ? 'cursor-pointer' : '', "select-none grow shrink basis-0 h-11 px-[18px] py-2.5 bg-white rounded-lg border border-gray-200 justify-center items-center gap-2 flex"].join(' ')}>
							<div className={[index > 0 ? "text-slate-700" : "text-slate-200", "text-base font-semibold font-['Inter'] leading-normal"].join(' ')}>
								Back
							</div>
						</div>
						<div
							onClick={
								() => {
									if (index >= workFilledTicSheetData.length - 1) {
										onDone && onDone();
									} else {
										const nextIndex = index < workFilledTicSheetData.length - 1 ? index + 1 : workFilledTicSheetData.length - 1;
										setPage(workFilledTicSheetData[nextIndex].page);
										setIndex(nextIndex);
									}
								}
							}
							className="select-none cursor-pointer grow shrink basis-0 h-11 px-[18px] py-2.5 bg-blue-900 rounded-lg shadow border border-gray-400 justify-center items-center gap-2 flex">
							<div
								className="text-gray-100 text-base font-semibold font-['Inter'] leading-normal">
								{index >= workFilledTicSheetData.length - 1 ? 'Done' : 'Next'}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div >
}

export default LassoFilledLinesBrowser;