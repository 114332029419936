import { FirebaseApp, initializeApp } from 'firebase/app';
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, Unsubscribe, User, UserCredential, getAuth, signInWithPopup, signInWithRedirect } from 'firebase/auth';
import { computed, makeAutoObservable, runInAction } from 'mobx';
import { logDebug } from '../shared/logger';
import { AttachmentFile, IEmailMetaData, IFilledTicSheetData, IJobResults, ITokenArrays, LineType, SelectionMark, TemplateCellTuple, TemplateMetaData } from '../shared/Azure';



class UserStore {
	public appReady = false;
	public user: User | null = null;
	private _firebaseApp: FirebaseApp;
	private _unsubscribe: Unsubscribe;
	constructor() {
		makeAutoObservable(this);
		const firebaseConfig = {
			apiKey: "AIzaSyAXOEBFqZ4P-3Rqss06xZLKOVybGUX4_4w",
			authDomain: "lasso-a234c.firebaseapp.com",
			projectId: "lasso-a234c",
			storageBucket: "lasso-a234c.appspot.com",
			messagingSenderId: "367777088649",
			appId: "1:367777088649:web:1eabe82d8c675695d83258",
			measurementId: "G-5GF405JLG2"
		};
		this._firebaseApp = initializeApp(firebaseConfig);
		const auth = getAuth();
		this._unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				this._setUser(user);
				// logDebug('LassoPage', user);
				this._setAppReady(true);
			} else {
				this._signIn();
			}
		});
	}
	private async _signIn(): Promise<UserCredential> {
		const provider = new GoogleAuthProvider();
		const user = await signInWithRedirect(getAuth(), provider);
		return user;
	}

	private _setAppReady(value: boolean) {
		runInAction(() => {
			this.appReady = value;
		});
	}


	private _setUser(user: User) {
		runInAction(() => {
			this.user = user;
		});
	}

}

class EstimateStore {
	public exportState: number = -1;
	public exportMessage: string = '';
	public estimateID: string = '';
	public processID: string = '';
	public pages: LineType.Page[] = [];
	public currentView: string = 'Estimate';
	public ticSheetHighlight: number[] = [];
	public selectedPage: number = 0;
	public email: string = 'yakir@golasso.ai';
	public phone: string = '(123) 456-7890';
	public insured: string = 'Yakir Elkayam';
	public claimNumber: string = '12345678909876';
	public insuredLast: string = 'Elkayam';
	public creatingPDF: boolean = false;
	// Today - 7 days;
	public dateContacted = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US');
	public dateOfLoss = new Date(Date.now() - 10 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US');
	public dateEstimateComplete = new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US');
	public insuranceCarrier = 'State Farm';
	public rawSheetData: LineType.Page[] = [];
	public templateMetaData: TemplateMetaData | null = null;
	public filledTicSheetData: IFilledTicSheetData[][] = [];
	public jobResults: IJobResults | null = null;
	public pageImageURL: string | undefined = '';
	public allFiles: AttachmentFile[] = [];
	private _netClaim: number = 123456;
	public dimensions: { w: number, h: number }[] = Array(100).fill({ w: 0, h: 0 });
	public updateLineItemExtracted(page: number, index: number, key?: ITokenArrays, value?: ITokenArrays, selected?: ITokenArrays) {
		value && (this.pages[page].lineItems[index].matchCell!.templateTuple.meta!.extracted!.extractedValueInput = value!);
		key && (this.pages[page].lineItems[index].matchCell!.templateTuple.meta!.extracted!.extractedKeyInput = key!);
		selected && (this.pages[page].lineItems[index].matchCell!.templateTuple.meta!.extracted!.extractedSelectedWords = selected!);
	}

	public setEmailMeta(meta?: IEmailMetaData) {
		if (!meta) return;
		this.email = meta.email;
		this.phone = meta.phone;
		this.insured = meta.insured;
		this.claimNumber = meta.claim;
		this.insuredLast = this.insured.split(/\s+/).pop() ?? '';
		// this.insuredLast = meta.insuredLast;
		// this.dateContacted = meta.dateContacted;
		// this.dateOfLoss = meta.dateOfLoss;
		// this.dateEstimateComplete = meta.dateEstimateComplete;
		// this.insuranceCarrier = meta.insuranceCarrier;

	}

	setCreatingPDF(value: boolean) {
		this.creatingPDF = value;
	}
	public get showDebug() {
		if (location.href.includes('production')) {
			return false;
		}
		return location.href.includes('localhost') || location.href.includes('192.168.') || location.href.includes('127.') || location.href.includes('debug');
	}

	public setAllFiles(value: AttachmentFile[]) {
		this.allFiles = value;
	}

	public setFilledTicSheetData(value: IFilledTicSheetData[][]) {
		this.filledTicSheetData = value;
	}


	public setJobResults(value: IJobResults) {
		this.jobResults = value;
	}

	public setRawSheetData(rawSheetData: LineType.Page[]) {
		this.rawSheetData = rawSheetData;
	}

	public setTemplateMetaData(templateMetaData: TemplateMetaData) {
		this.templateMetaData = templateMetaData;
	}


	public get netClaim() {
		return this._netClaim;
	}

	constructor() {
		makeAutoObservable(this);
	}

	public setRoomDimensions(index: number, dimensions: { w: number, h: number }) {
		this.dimensions[index] = { ...dimensions };
	}

	public calculateTotal() {
		//@ts-ignore
		this._netClaim = Array.from(document.querySelectorAll('.cellIndex-7')).map(val => val.innerText).map(val => parseFloat(val)).reduce((a, b) => a + b, 0);

	}
	public getRelativePageIndex(page: number) {
		if (!this.jobResults) return page;
		if (this.jobResults.pdfResults.length === 0) return page;
		let index = 0;
		let runningIndex = 0;
		do {
			const _runningIndex = runningIndex + this.jobResults!.pdfResults[index].pages.length;
			if (page < _runningIndex) {
				return page - runningIndex;
			}
			runningIndex = _runningIndex;
		} while (index < this.jobResults!.pdfResults.length);
		return page;
	}

	public getPdfIdAndPageFromPageIndex(page: number): {
		pdfID?: string;
		page?: LineType.Page;
	} {
		if (!this.jobResults) return {};
		if (this.jobResults.pdfResults.length === 0) return {};
		let index = 0;
		let runningIndex = 0;
		do {
			const _runningIndex = runningIndex + this.jobResults!.pdfResults[index].pages.length;
			if (page < _runningIndex) {
				return {
					pdfID: this.jobResults!.pdfResults[index].pdfID,
					page: this.jobResults!.pdfResults[index].pages[page - runningIndex]
				}
			}
			index++;
			runningIndex = _runningIndex;
		} while (index < this.jobResults!.pdfResults.length);
		return {};
	}

	public setCurrentView(view: string, pageImageURL?: string, highlight?: number[]) {
		this.currentView = view;
		this.pageImageURL = pageImageURL;
		this.ticSheetHighlight = highlight ?? [];
	}


	public addLineItem(index: number, lineItem: LineType.Line) {
		this.pages[index].lineItems.push(lineItem);
	}
	public setEstimateID(estimateID: string) {
		this.estimateID = estimateID;
	}

	public setProcessID(processID: string) {
		this.processID = processID;
	}

	public setPages(pages: LineType.Page[]) {
		this.pages = pages;
	}

	public setSelectPage(page: number) {
		this.selectedPage = page;
	}


	public getDebris(page: number) {
		if (!this.pages.length) return [];
		return this.pages[page].header?.debris;
	}

	public get address() {
		if (!this.pages.length) return '';
		return this.pages[0].header?.address.map((c) => c.content).join(' ').replace('Address', '').replaceAll(':', '').trim();
	}

	public get cityAndState() {
		return 'New York, NY, 10001';
	}

	public get roomNames() {
		return this.pages.map((page) => {
			const roomName = page.header?.room.map((c) => c.content).join(' ').replace('ROOM', '').replace(':', '').replace(';', '').trim();
			if (!roomName) {
				return 'Missing Room Name'
			}
			if (roomName?.length < 3) {
				return 'Missing Room Name'
			}
			return roomName;
		});
	}

	public get estimateRef() {
		if (!this.pages.length) return '';
		let dash = this.pages[0].header?.dash.map((c) => c.content).join(' ').replace('Dash', '').replaceAll('#', '') ?? '';
		// remove all empty spaces
		dash = dash.replace(/\s/g, '');
		return dash;

	}
}





const _userStore: UserStore = new UserStore();
const getUserStore = () => _userStore;

const _estimateStore: EstimateStore = new EstimateStore();
const getEstimateStore = () => _estimateStore;


export const debugStyle = (style: string, color: string) => {
	const s = style + (_estimateStore.showDebug ? ` ${color}` : '');
	// logDebug('s', s, style);
	return s;
}

export {
	getEstimateStore,
	getUserStore
};
