import { observer } from 'mobx-react';
import { useRef } from 'react';
import { getEstimateStore } from '../../../data/DataStores';

const LassoExportDialog: React.FC = observer(() => {
	const estimateStore = useRef(getEstimateStore());

	return <>
		{
			estimateStore.current.exportState >= 0 &&
			<div style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
				zIndex: 1000,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}>
				<div className="w-[304px] h-[126px] px-6 py-4 bg-white rounded-xl border border-gray-200 flex-col justify-start items-center gap-1 inline-flex">
					<div className="self-stretch h-[90px] flex-col justify-start items-center gap-3 flex">
						<div >
							{
								estimateStore.current.exportMessage.toLowerCase().includes('xactimate') && <img src='/assets/ui/exportDlg.svg' alt="Exporting" className='h-10' />
							}
							{
								estimateStore.current.exportMessage.toLowerCase().includes('symbility') && <img src='/assets/ui/symbility.png' alt="Exporting" className='h-10' />
							}
							{
								estimateStore.current.exportMessage.toLowerCase().includes('pdf') && <img src='/assets/ui/PDF_file_icon.svg' alt="Exporting" className='h-10' />
							}
						</div>
						<div className="w-64 justify-start items-center gap-3 inline-flex">
							<div className="w-64 h-2 relative rounded-lg">
								<div className="w-64 h-2 left-0 top-0 absolute bg-gray-200 rounded" />
								<div style={{
									width: `${(256 * (estimateStore.current.exportState / 4)).toFixed(0)}px`,
									transition: 'width 3s',
								}}
									className="h-2 left-0 top-0 absolute bg-blue-500 rounded" />
							</div>
						</div>
						<div className="self-stretch text-center text-gray-400 text-xs font-normal font-['Inter'] leading-[18px]">{estimateStore.current.exportMessage}... </div>
					</div>
				</div>
			</div>
		}
	</>
});

export default LassoExportDialog;