const LoadingAnimation = () => {
	return <div style={{
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		zIndex: 1000,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}}>
		<div style={{
			backgroundColor: 'white',
			padding: '20px',
			borderRadius: '10px'
		}}>
			Processing...
		</div>
	</div>
}

export default LoadingAnimation;