import React, { useEffect, useState } from 'react';

const AreYouSureModal: React.FC<{ callback: (canceled: boolean) => void }> = ({ callback }) => {
	useEffect(() => {
		const dontShowAgain = window.localStorage.getItem('dontShowAgain');
		if (dontShowAgain === '1') {
			callback(true);
		}
	});

	return <div className="w-[544px] h-[196px] bg-white rounded-xl shadow flex-col justify-start items-center inline-flex">
		<div className="self-stretch h-24 bg-white flex-col justify-start items-center flex">
			<div className="self-stretch px-6 pt-6 bg-white justify-start items-start gap-4 inline-flex">
				<div className="w-12 h-12 p-3 bg-red-100 rounded-[28px] border-8 border-red-50 justify-center items-center flex">
					<div className="w-6 h-6 relative flex-col justify-start items-start flex" />

					<img src='/assets/ui/trash-02.svg' className='' width={'20px'} height={'20px'} alt='trash-icon' style={{ position: 'absolute' }} />
				</div>
				<div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
					<div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7 select-none">Delete line item</div>
					<div className="self-stretch text-slate-600 text-sm font-normal font-['Inter'] leading-tight select-none">Are you sure you want to delete this item? This action cannot be undone.</div>
				</div>
			</div>
			<div className="p-2.5 rounded-lg justify-center items-center inline-flex">
				<div className="w-6 h-6 relative" />
			</div>
		</div>
		<div className="self-stretch h-[100px] pt-8 flex-col justify-center items-start flex">
			<div className="self-stretch pl-[88px] pr-6 pb-6 justify-start items-center gap-3 inline-flex">
				<div className="justify-start items-start gap-2 flex">
					<div className=" mb-2 pt-0.5 justify-center items-center flex">
						<input type="checkbox" className="w-4 h-4 rounded-[4px] border border-gray-200 cursor-pointer" onChange={(e) => {
							window.localStorage.setItem('dontShowAgain', e.target.checked ? '1' : '0');
						}} />
						<div className="ml-2  flex-col justify-start items-start inline-flex " >
							<div className="text-slate-700 text-sm font-medium font-['Inter'] leading-tight select-none">Don’t show again</div>
						</div>
					</div>
				</div>
				<div className="grow shrink basis-0 h-11 justify-end items-center gap-3 flex">
					<div onClick={
						() => {
							callback(true);
						}
					} className="px-[18px] py-2.5 bg-white rounded-lg border border-gray-200 justify-center items-center gap-2 flex cursor-pointer">
						<div className="text-slate-700 text-base font-semibold font-['Inter'] leading-normal select-none">Cancel</div>
					</div>
					<div onClick={
						() => {
							callback(false);
						}
					} className="px-[18px] py-2.5 bg-red-600 rounded-lg shadow border border-red-600 justify-center items-center gap-2 flex cursor-pointer">
						<div
							className="text-white text-base font-semibold font-['Inter'] leading-normal select-none">Delete</div>
					</div>
				</div>
			</div>
		</div>
	</div>
}

export default AreYouSureModal;