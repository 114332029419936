import React, { useEffect } from 'react';
import RestAPI from '../utils/restAPI';
import UploadFileButton from '../components/utils/UploadFileButton';
import { IJobResults } from '../shared/Azure';

const LassoJobs: React.FC = () => {
	const [jobs, setJobs] = React.useState([]);
	const [emails, setEmails] = React.useState([]);
	const [lineItems, setLineItems] = React.useState<any[][]>([]);
	const [forceRender, setForceRender] = React.useState(0);
	useEffect(() => {
		loadJobs();
		const interval = setInterval(() => {
			loadJobs();
		}, location.href.includes('localhost') ? 5000000 : 5000);
		return () => clearInterval(interval);
	}, []);

	async function loadJobs() {
		const ret = await RestAPI.fetchAPI('loadJobs');
		setJobs(ret.jobs);
		setEmails(ret.emails);
	}

	async function newJob(base64Data: string) {
		const ret = await RestAPI.fetchAPI('createNewJob', 'POST', { base64Pdf: base64Data, configJson: 'X' });
		setForceRender(forceRender + 1);
	}

	async function showLineItems(job: any) {
		if (lineItems.length > 0) {
			setLineItems([]);
			return;
		}
		if (job.status !== 'done') return;
		const ret = await RestAPI.fetchAPI('getJob', 'GET', { jobId: job.jobId }) as IJobResults;
		// const pages = ret.pdfResults.flatMap((page) => page.filledTicSheetData);
		// setLineItems(pages);
	}

	return <div className='m-2'>
		<div className='m-2'>
			Jobs:
			{
				jobs.length !== 0 && jobs.map((job: any) => {
					return <div
						onClick={() => showLineItems(job)}
						style={{ cursor: 'pointer' }} key={job.processID}>

						{job.jobId} - {job.status}
					</div>
				})
			}
			{
				!jobs.length && <div>No jobs</div>
			}
			<br></br>
			Emails:
			{
				emails.length !== 0 && emails.map((email: any) => {
					return <div
						// onClick={() => showLineItems(job)}
						// style={{ cursor: 'pointer' }}
						key={email.emailId}>
						{email.emailId} - {email.status}
					</div>
				})
			}
			{
				!emails.length && <div>No emails</div>
			}
			{
				lineItems.length !== 0 && lineItems.map((room: any, index: number) => {
					return <div key={index}>
						<br></br>
						<div>room {index}</div>
						{
							room.map((item: any, index: number) => {
								return <div key={index}>{item.lineItem[1]} - {item.lineItem[3]}<br />{JSON.stringify(item)}</div>
							})
						}
					</div>
				})
			}
		</div>
		<div className='flex'>
			<UploadFileButton key={forceRender} onLoad={newJob} accept="application/pdf" title='New Job' />
			<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2 w-32" onClick={loadJobs}>Refresh</button>
		</div>

	</div>
};


export default LassoJobs;

