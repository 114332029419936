import React, { useCallback, useEffect, useRef } from 'react';
import './LassoRoomPanel.scss';
import { debugStyle, getEstimateStore } from '../../../data/DataStores';
import { observer } from 'mobx-react';
import AreYouSureModal from '../utils/AreYouSureModal';
import { IEstimateResults, LineType } from '../../../shared/Azure';
import RestAPI from '../../../utils/restAPI';
import { logDebug } from '../../../shared/logger';
import SharedUtils from '../../../shared/SharedUtils';
import JaroWinklerDistance from '../../../shared/JaroWinklerDistance';



const LassoCell: React.FC<{ onValueChanged: (val: string) => void, edit: boolean, initialValue: string, lineIndex: number, cellIndex: number }> = ({ onValueChanged, edit, initialValue, cellIndex, lineIndex }) => {
	const divRef = useRef<HTMLDivElement>(null);
	const [editableValue, setEditableValue] = React.useState(false);
	const [value, setValue] = React.useState(initialValue);

	const pd = useCallback((e: KeyboardEvent) => {
		if (e.key === 'Enter') {
			e.preventDefault()
			setEditableValue(false)
		}
	}, []);


	useEffect(() => {
		divRef.current?.addEventListener('keydown', pd);
		return () => {
			divRef.current?.removeEventListener('keydown', pd);
			// divRef.current?.removeEventListener('input', sv);
		}

	}, [divRef]);
	useEffect(() => {
		if (editableValue) {
			divRef.current?.focus();
		} else {
			setValue(divRef.current?.innerText ?? '');
			onValueChanged(divRef.current?.innerText ?? '');
		}
	}, [editableValue]);
	return <td>
		<div

			ref={divRef}
			dangerouslySetInnerHTML={{ __html: value }}
			onBlur={() => setEditableValue(false)}
			style={{ pointerEvents: edit ? 'auto' : 'none', height: 25, boxSizing: 'border-box', }}
			className={`editable lineIndex-${lineIndex} cellIndex-${cellIndex} ${edit ? 'frame' : ''}`} contentEditable={editableValue} onClick={() => { if (edit) setEditableValue(true) }}>
		</div>
	</td>
}

const LassoRoomLineItem: React.FC<{
	estimateData: IEstimateResults,
	pageImageURL: string,
	line?: LineType.Line,
	lineIndex: number
}> = observer(({ pageImageURL, line, lineIndex, estimateData }) => {
	const estimateStore = useRef(getEstimateStore());
	const [deleted, setDeleted] = React.useState(false);
	const [edit, setEdit] = React.useState(false);
	const [showDetails, setShowDetails] = React.useState(false);
	const [values, setValues] = React.useState<any[]>([]);
	const [initialValues, setIniticalValues] = React.useState<any[]>([]);
	const [areYouSureModal, setAreYouSureModal] = React.useState(false);
	function showLineOnTicSheet() {
		if (!line || !line.matchCell) return;
		const leftBrounds = line.matchCell?.azureCells[0].boundingRegions[0];
		const rightBrounds = line.matchCell?.azureCells[1].boundingRegions[0];
		// debugger;
		// find biggest bounding box
		const x = Math.min(leftBrounds.polygon[0].x, rightBrounds.polygon[0].x) - 10;
		const y = Math.min(leftBrounds.polygon[0].y, rightBrounds.polygon[0].y) - 10;
		const width = Math.max(leftBrounds.polygon[2].x, rightBrounds.polygon[2].x) - x + 10;
		const height = Math.max(leftBrounds.polygon[2].y, rightBrounds.polygon[2].y) - y + 10;
		estimateStore.current.setCurrentView('Tic Sheet', pageImageURL, [x, y, width, height]);
	}


	useEffect(() => {
		const vv = [
			(lineIndex + 1).toFixed(),//<th>No.</th>
			estimateData?.finalText,//<th>Act. Description</th>
			`${estimateData?.quantity?.count ?? 0} ${estimateData?.quantity?.measurement ?? ''}`.toUpperCase(),//<th>QTY</th>
			((estimateData?.remove || 0)).toFixed(2), // <th>Remove</th>
			((estimateData?.replace || 0)).toFixed(2), // <th>Replace</th>
			((estimateData?.costTax || 0)).toFixed(2), // <th>Tax</th>
			((estimateData?.costOnP || 0)).toFixed(2), // <th>O&P</th>
			((estimateData?.estimate || 0)).toFixed(2), // <th>Total</th>
		]
		setValues([...vv]);
		setIniticalValues([...vv]);
	}, []);

	function doDelete() {
		setAreYouSureModal(true);
	}

	if (deleted || values.length === 0) return <></>;
	return <>
		<tr style={{ cursor: 'pointer' }} className={showDetails ? 'selected' : ''} onClick={() => { if (!edit) setShowDetails(!showDetails) }}>
			{
				values.map((value, i) => {
					return <LassoCell key={`${i}-${value}`} lineIndex={lineIndex} cellIndex={i} edit={edit && (i === 1 || i === 2 || i === 3 || i === 4)} initialValue={value} onValueChanged={(newVal) => {
						// logDebug('newVal' + newVal);
						const newValues = [...values];
						newValues[i] = newVal;
						const cost = parseFloat(newValues[4]) * parseInt(newValues[2]) + parseFloat(newValues[3]) * parseInt(newValues[2]);
						const costTax = cost * (7.95 / 100);
						const costOnP = cost * (20 / 100);
						const estimate = cost + costTax + costOnP;
						newValues.pop();
						newValues.pop();
						newValues.pop();
						newValues.push(costTax.toFixed(2));
						newValues.push(costOnP.toFixed(2));
						newValues.push(estimate.toFixed(2));
						// logDebug('newValues', newValues, values);
						setValues(newValues);
						setTimeout(() => {
							estimateStore.current.calculateTotal();
						}, 100);
					}} />
				})
			}
		</tr>
		{
			showDetails && <tr className='details-row '>
				<td colSpan={10} className='w-[100%] '>
					<div className='flex justify-between '>
						<div className='flex items-center'>
							<div className='mr-2'>Generated From: </div>
							<button className='white-button' onClick={showLineOnTicSheet}>Tic Sheet</button>
							<button className='blue-button'><img src='/assets/ui/map-02.svg'></img>View on Floor Plan</button>
						</div>
						{
							!edit && <div className='flex items-center '>
								<button className='edit-button' onClick={() => setEdit(true)}><img src='/assets/ui/edit-03.svg'></img></button>
								<button className='delete-button' onClick={doDelete}><img src='/assets/ui/trash-01.svg'></img></button>
							</div>
						}
						{
							edit && <div className='flex items-center '>
								<button className='blue-button' onClick={() => {
									setEdit(false);
									setIniticalValues([...values]);
								}}>Update</button>
								<button className='white-button' onClick={() => {
									setEdit(false);
									setValues([...initialValues]);
								}}>Cancel</button>
							</div>
						}
					</div>
				</td>
			</tr>
		}
		<tr>
			<div>
				{
					areYouSureModal &&
					<div style={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
						zIndex: 1000,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}>
						<div style={{
							// position: 'fixed',
							// top: '35%',
							// left: '35%',
						}}>
							<AreYouSureModal callback={(canceled) => {
								setAreYouSureModal(false);
								if (!canceled) {
									setDeleted(true);
									setTimeout(() => {
										estimateStore.current.calculateTotal();
									}, 100);
								}
							}} />
						</div>
					</div>
				}
			</div>
		</tr>
	</>
});

const LassoRoomTable: React.FC<{ index: number }> = observer(({ index }) => {
	const estimateStore = useRef(getEstimateStore());
	let pdfId = estimateStore.current.getPdfIdAndPageFromPageIndex(index);
	if (!pdfId.pdfID) alert('no pdf id');
	if (!pdfId.pdfID!.startsWith('pdf_')) pdfId.pdfID = 'pdf_' + pdfId;
	const pageImageURL = RestAPI.getServerBaseUrl(pdfId.page?.pngFile!);
	if (estimateStore.current.pages.length === 0 || index >= estimateStore.current.pages.length) return <div></div>;
	const roomLineItemsJSXArray = [] as JSX.Element[];
	let lineIndex = 0;

	const debris = estimateStore.current.getDebris(index);
	let debrisType = '';
	let cubicYards = '';
	debris?.forEach((word, i) => {
		if (word.selected) {
			if (!debrisType) {
				const jaro = (new JaroWinklerDistance());
				if (jaro.distance(word.content.toLowerCase(), 'dumpster') > 0.8) {
					debrisType = 'dumpster';
				}
				else if (jaro.distance(word.content.toLowerCase(), 'pickup') > 0.8) {
					debrisType = 'pickup truck';
				} else if (jaro.distance(word.content.toLowerCase(), 'tandem') > 0.8) {
					debrisType = 'tandem dump truck';
				} else if (jaro.distance(word.content.toLowerCase(), 'truck') > 0.6 || jaro.distance(word.content.toLowerCase(), 'dump') > 0.6) {
					debrisType = 'dump truck';
				}
			}
			if (!cubicYards) {
				const jaro = (new JaroWinklerDistance());
				if (jaro.distance(word.content.toLowerCase(), '(12)') > 0.8) {
					cubicYards = '12';
				} else if (jaro.distance(word.content.toLowerCase(), '(20)') > 0.8) {
					cubicYards = '20';
				} else if (jaro.distance(word.content.toLowerCase(), '(30)') > 0.8) {
					cubicYards = '30';
				} else if (jaro.distance(word.content.toLowerCase(), '(40)') > 0.8) {
					cubicYards = '40';
				}
			}
		}
	})
	if (debrisType) {
		const finalText = debrisType === 'dumpster' ? `Haul debris - dumpster ${cubicYards} cubic yards - includes dump fees` : `Haul debris - per ${debrisType} load - includes dump fees`;
		const res: IEstimateResults = {
			finalText,
			quantity: {
				count: 1,
				measurement: 'EA'
			},
			remove: 222.34
		}
		roomLineItemsJSXArray.push(<LassoRoomLineItem key={`${lineIndex}-debris`} estimateData={res} lineIndex={lineIndex} line={undefined} pageImageURL={pageImageURL} />);
		lineIndex++;
	}
	SharedUtils.startRoomProcessing();
	estimateStore.current.pages[index].lineItems.forEach((line) => {
		const allValues = [...(line.matchCell?.templateTuple.meta.extracted?.extractedKeyInput.acceptedTokens ?? []),
		...(line.matchCell?.templateTuple.meta.extracted?.extractedValueInput.acceptedTokens ?? []),
		...(line.matchCell?.templateTuple.meta.extracted?.extractedSelectedWords.acceptedTokens ?? []),
		...(line.matchCell?.templateTuple.meta.extracted?.extractedSelectedWords.rejectedTokens ?? []),
		...(line.matchCell?.templateTuple.meta.extracted?.extractedKeyInput.rejectedTokens ?? []),
		...(line.matchCell?.templateTuple.meta.extracted?.extractedValueInput.rejectedTokens ?? []),
		];
		const haveValues = allValues.some(v => v.trim().length > 0);
		if (!haveValues && line.lineItem[0].trim().length === 0) {
			return;
		}
		// let estimateData: IEstimateResults = {
		// 	finalText: '',
		// 	quantity: {
		// 		count: 0,
		// 		measurement: ''
		// 	},
		// 	remove: 0,
		// 	replace: 0,
		// 	costTax: 0,
		// 	costOnP: 0,
		// 	estimate: 0
		// };
		if (line.matchCell) {
			const data = SharedUtils.processLineItemToEstimateLine(line.matchCell!.templateTuple!.meta!, undefined, undefined, undefined, estimateStore.current.dimensions[index]);
			if (data.length) {
				data.forEach((_estimateData) => {
					const keyFromEstimateData = `${_estimateData.finalText}-${_estimateData.quantity?.count}-${_estimateData.quantity?.measurement}-${_estimateData.remove}-${_estimateData.replace}-${_estimateData.costTax}-${_estimateData.costOnP}-${_estimateData.estimate}`;
					roomLineItemsJSXArray.push(<LassoRoomLineItem estimateData={_estimateData} key={`${lineIndex}-${keyFromEstimateData}`} lineIndex={lineIndex} line={line} pageImageURL={pageImageURL} />);
					lineIndex++;
				});
				return;
			}
		}
		// const keyFromEstimateData = `${estimateData.finalText}-${estimateData.quantity?.count}-${estimateData.quantity?.measurement}-${estimateData.remove}-${estimateData.replace}-${estimateData.costTax}-${estimateData.costOnP}-${estimateData.estimate}`;
		// roomLineItemsJSXArray.push(<LassoRoomLineItem key={`${lineIndex}-${keyFromEstimateData}`} estimateData={estimateData} lineIndex={lineIndex} line={line} pageImageURL={pageImageURL} />);
		// lineIndex++;
	});

	if (pdfId.page?.freeTextLines && pdfId.page?.freeTextLines.length > 0) {
		pdfId.page!.freeTextLines.forEach((line) => {
			const la = line.split('@@@');
			if (la[0] === 'scrape concrete wall') {
				const regex = /^(.*?)(\d+)$/;
				const match = la[1].match(regex);
				if (match) {
					const number = parseInt(match[2], 10);
					let estimateData: IEstimateResults = {
						finalText: 'Scrape the surface area & prep for paint',
						quantity: {
							count: number,
							measurement: 'SF'
						},
						remove: 0.84,
						replace: 0,
						costTax: 0,
						costOnP: 0,
						estimate: 0
					};
					const keyFromEstimateData = `freeText-scrape concrete wall`;
					roomLineItemsJSXArray.push(<LassoRoomLineItem key={`${lineIndex}-${keyFromEstimateData}`} estimateData={estimateData} lineIndex={lineIndex} pageImageURL={pageImageURL} />);
					lineIndex++;
				}

			}
		});

	}
	// Add final clean up
	let estimateData: IEstimateResults = {
		finalText: 'Final cleaning - construction - Residential',
		quantity: {
			count: parseFloat((estimateStore.current.dimensions[index].h * estimateStore.current.dimensions[index].w).toFixed(2)),
			measurement: 'SF'
		},
		remove: 0.36,
		replace: 0,
		costTax: 0,
		costOnP: 0,
		estimate: 0
	};
	logDebug('dimensions', index, estimateData, (estimateStore.current.dimensions[index].h * estimateStore.current.dimensions[index].w).toFixed(2))
	const keyFromEstimateData = `Final cleaning - ${lineIndex} - ${Date.now()}`;
	roomLineItemsJSXArray.push(<LassoRoomLineItem key={`${lineIndex}-${keyFromEstimateData}`} estimateData={estimateData} lineIndex={lineIndex} pageImageURL={pageImageURL} />);
	lineIndex++;
	const thStyle = {
		paddingBottom: estimateStore.current.creatingPDF ? 10 : 0
	}
	return <>
		<table className='lasso-room-table'>
			<thead >
				<th style={thStyle}>No.</th>
				<th style={thStyle}>Act. Description</th>
				<th style={thStyle}>QTY</th>
				<th style={thStyle}>Remove</th>
				<th style={thStyle}>Replace</th>
				<th style={thStyle}>Tax</th>
				<th style={thStyle}>O&P</th>
				<th style={thStyle}>Total</th>
			</thead>
			<tbody>
				{
					roomLineItemsJSXArray
				}
			</tbody>
		</table>
	</>
});

const LassoRoomProps: React.FC<{ roomIndex: number }> = observer(({ roomIndex }) => {
	const estimateStore = useRef(getEstimateStore());
	const dim = estimateStore.current.dimensions[roomIndex];
	return <div className={debugStyle(`flex w - [500px] justify - between`, 'bg-red-100')}>
		{/* TODO :: check where to get room dimentions from */}
		<table>
			<tbody>
				<tr>
					<td className='pr-2 '>Room dimentions</td>
					<td className='pr-2 pl-2'>Wall Area</td>
					<td className=' pl-2'>Door Count</td>
				</tr>
				<tr>
					<td className='pr-2 '>{SharedUtils.sizeString2(dim)}</td>
					<td className='pr-2 pl-2'>{Math.round(dim.h * dim.w).toFixed(0)} sqft</td>
					<td className=' pl-2'>2</td>
				</tr>
			</tbody>
		</table>
		{/* <div>
			<div className='menu-title'>Room dimentions</div>
			<div className=''>11X33</div>
		</div>
		<div>
			<div className='menu-title'>Wall Area</div>
			<div className=''>445 sqft</div>
		</div>
		<div>
			<div className='menu-title'>Door Count</div>
			<div className=''>2</div>
		</div> */}
	</div>
});

const LassoRoomPanel: React.FC = observer(() => {
	const estimateStore = useRef(getEstimateStore());
	const curPage = useRef(estimateStore.current.selectedPage);
	useEffect(() => {
		if (curPage.current === estimateStore.current.selectedPage) return;
		const d = document.getElementById(`scrollPage-${estimateStore.current.selectedPage}`);
		if (d) {
			d.scrollIntoView({ behavior: 'smooth', block: 'start' });
			curPage.current = estimateStore.current.selectedPage;
		}
	}, [estimateStore.current.selectedPage]);
	return <div className='h-[600px] overflow-y-scroll'>
		<div className='mr-8 ml-8 '>

			{
				estimateStore.current.roomNames.map((room, i) => {
					return <div id={`scrollPage-${i}`} key={i}>
						<div className={['room-panel-container w-[100%] mt-12', estimateStore.current.creatingPDF ? '' : 'shadow'].join(' ')}>
							<div className='ml-4'>
								<div className='room-title mb-4 mt-2'>{room}</div>
								<LassoRoomProps roomIndex={i} />
							</div>
							<div className=''>
								<LassoRoomTable index={i} />
							</div>
						</div>
						{
							!estimateStore.current.creatingPDF &&
							<div className='flex justify-end mt-2'>
								<button className='blue-button' onClick={() => {
									estimateStore.current.addLineItem(0, { lineItem: ['New line item', '0', '0', '0.0'] });
								}}>Add Line Item</button>
							</div>}
					</div>
				})
			}
			{/* <div className='room-panel-container w-[100%] mt-12 '>
				<div className='ml-4'>
					<div className='room-title'>Bedroom 1</div>
					<LassoRoomProps />
				</div>
				<div className=''>
					<LassoRoomTable index={1} />
				</div>
			</div> */}
			<div className="mb-16"></div>
		</div>
	</div>

})
export default LassoRoomPanel;