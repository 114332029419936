import React, { useRef } from 'react';
import './LassoEstimationPanel.scss';
import { observer } from 'mobx-react';
import { getEstimateStore } from '../../../data/DataStores';





const LassoEstimatePanel: React.FC = observer(() => {
	const estimateStore = useRef(getEstimateStore());
	return <div>
		<div style={{
			borderBottom: '1px solid #F2F4F7',
		}}>
			<div className='reference-title'>
				Estimate Reference Number
			</div>
			<div className='estimate-value mb-[24px]'>
				{
					estimateStore.current.estimateRef
				}
			</div>
		</div>
		<table className='estimate-panel-table'>
			<tbody>
				<tr>
					<td>
						<div className='estimate-title'>
							Insured
						</div>
						<div className='estimate-value'>
							{
								estimateStore.current.insured
							}
						</div>
					</td>
					<td>
						<div className='estimate-title'>
							Claim Number
						</div>
						<div className='estimate-value'>
							{
								estimateStore.current.claimNumber
							}
						</div>
					</td>
					<td>
						<div className='estimate-title'>
							Phone
						</div>
						<div className='estimate-value'>
							{
								estimateStore.current.phone
							}
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div className='estimate-title'>
							Property
						</div>
						<div className='estimate-value'>
							{
								estimateStore.current.address
							}
							<br />
							{
								estimateStore.current.cityAndState
							}
						</div>
					</td>
					<td>
						<div className='estimate-title'>
							Home
						</div>
						<div className='estimate-value'>
							{
								estimateStore.current.address
							}
							<br />
							{
								estimateStore.current.cityAndState
							}
						</div>
					</td>
					<td>
						<div className='estimate-title'>
							Email
						</div>
						<div className='estimate-value'>
							{
								estimateStore.current.email
							}
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
});

const LassoEstimatesPanel: React.FC = observer(() => {
	const estimateStore = useRef(getEstimateStore());
	return <div>
		<div className='reference-title  mb-[24px]'>
			Estimate Sections
		</div>
		<div className='estimate-sections-container'>
			{
				estimateStore.current.roomNames.map((room, index) => {
					return <div
						onClick={() => {
							estimateStore.current.setSelectPage(index);
						}}
						className={`estimate-section ${index === estimateStore.current.selectedPage ? 'selected' : ''}`} key={index}>
						{room}
					</div>
				})
			}
		</div>
	</div>
});

const LassoInsuraneCarrierPanel: React.FC = observer(() => {
	const estimateStore = useRef(getEstimateStore());
	return <div className='insurance-carrier-container '>
		<div>
			Insurance Carrier:
			<div>
				<strong>
					{
						estimateStore.current.insuranceCarrier
					}
				</strong>
			</div>
		</div>
		<div>
			Date Contacted:<br />
			{
				estimateStore.current.dateContacted
			}
		</div>
		<div>
			Date of Loss:<br />
			{
				estimateStore.current.dateOfLoss
			}
		</div>
		<div>
			Date Est. Completed:<br />
			{
				estimateStore.current.dateEstimateComplete
			}
		</div>
		<div>
			Net Claim:<br />
			{
				estimateStore.current.netClaim.toFixed(2)
			}
		</div>
	</div>
});

const LassoEstimationPanel: React.FC = observer(() => {
	const estimateStore = useRef(getEstimateStore());
	return <div className='flex mr-8 ml-8'>
		<div className='flex flex-col mr-auto'>
			<LassoEstimatePanel />
			{!estimateStore.current.creatingPDF && <LassoEstimatesPanel />}
		</div>
		<LassoInsuraneCarrierPanel />
	</div>
});
export default LassoEstimationPanel;