import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LassoTopBar from '../components/ui/lasso/LassoTopBar';
import LassoEstimationPanel from '../components/ui/lasso/LassoEstimationPanel';
import LassoRoomPanel from '../components/ui/lasso/LassoRoomPanel';
import { observer } from 'mobx-react';
import RestAPI from '../utils/restAPI';
import { debugStyle, getEstimateStore } from '../data/DataStores';
import { IFilledTicSheetData, IJobResults } from '../shared/Azure';
import LassoTicSheetPanel from '../components/ui/lasso/LassoTicSheetPanel';
import LassoImagesPanel from '../components/ui/lasso/LassoImagesPanel';
import PrivateUtils from '../utils/PrivateUtils';
import LassoExportDialog from '../components/ui/lasso/LassoExportDialog';
import SharedUtils from '../shared/SharedUtils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LassoAreaDialog from '../components/ui/lasso/LassoAreaDialog';
import LassoConflictsWizard from '../components/ui/lasso/LassoConflictsWizard';

const LassoPage: React.FC = observer(() => {
	const estimateStore = useRef(getEstimateStore());
	const [params] = useSearchParams();
	const [loading, setLoading] = useState<boolean>(false);
	const estimateID = params.get('estimateID')!;
	const processID = params.get('processID');
	const [haveBadValues, setHaveBadValues] = useState<boolean>(false);
	const [roomAreaDialogShowen, setRoomAreaDialogShowen] = useState<boolean>(false);
	estimateStore.current.setEstimateID(estimateID);
	estimateStore.current.setProcessID(processID!);
	const notify = (text: string) => toast(
		<div className='flex items-center'>
			<img src='/assets/ui/success.png' alt='Success' className='w-12 h-12' />
			<div className='mr-4 ml-4 text-gray-900 pr-16'>
				Estimate exported to {text}
			</div>
		</div>
	);

	function _setHaveBadValuesIfNeeded(filledTicSheetData: IFilledTicSheetData[][]) {
		let haveBadValues = false;
		filledTicSheetData.forEach((data) => {
			data.forEach((item) => {
				if (item.meta && (item.meta.extracted?.extractedKeyInput?.rejectedTokens?.length || item.meta.extracted?.extractedValueInput?.rejectedTokens?.length)) {
					haveBadValues = true;
				}
			});
		});
		setHaveBadValues(haveBadValues);
	}

	async function fetchEstimate() {
		if (processID && !loading) {
			setLoading(true);
			const ret = (await RestAPI.fetchAPI('getJob', 'GET', { jobId: processID })) as IJobResults;
			if (ret) {
				estimateStore.current.setEmailMeta(ret.emailMetaData);
				estimateStore.current.setJobResults(ret);
				const filledTicSheetData = ret.pdfResults.flatMap((page) => page.filledTicSheetData!);
				const pages = ret.pdfResults.flatMap((page) => page.pages);
				if (pages && filledTicSheetData && pages.length === filledTicSheetData.length) {
					estimateStore.current.setPages(pages);
					estimateStore.current.setFilledTicSheetData(filledTicSheetData);
					estimateStore.current.setAllFiles(ret.files);
					_setHaveBadValuesIfNeeded(filledTicSheetData);
				} else {
					alert('Error in fetching estimate');
				}
			}
			// logDebug('fetchEstimate', ret);
			setLoading(false);
			setTimeout(() => {
				estimateStore.current.calculateTotal();
			}, 100);
		}
	}

	useEffect(() => {
		fetchEstimate();
	}, []);

	// logDebug('LassoPage', estimateID);

	if (loading || estimateStore.current.roomNames.length === 0) {
		return <div>Loading...</div>;
	}


	return <div className={debugStyle('ml-4 mr-4', 'bg-cyan-100')}>
		<div className={debugStyle('ml-[73px] mr-[73px]', 'bg-red-100 ')} >
			<LassoTopBar />
		</div>
		{
			estimateStore.current.currentView === 'Estimate' && <>
				<div id='topEstimatePanel' key='estimate' className={debugStyle('ml-[73px] mr-[73px]', 'bg-blue-100')}>
					<LassoEstimationPanel />
				</div>
				<div key='room' className={debugStyle('ml-[73px] mr-[73px]', 'bg-pink-100 ')} >
					<LassoRoomPanel />
				</div>
			</>
		}
		{
			estimateStore.current.currentView === 'Tic Sheet' && <LassoTicSheetPanel />
		}
		{
			estimateStore.current.currentView === 'Photos' && <LassoImagesPanel />
		}
		{
			!roomAreaDialogShowen && <LassoAreaDialog onDone={() => {
				setRoomAreaDialogShowen(true)
			}} />
		}
		{
			roomAreaDialogShowen && haveBadValues && <LassoConflictsWizard onDone={() => {
				setHaveBadValues(false)
			}} />
		}
		{
			<LassoExportDialog />
		}
		<div className="mb-16">
		</div>
		<div style={{
			boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.05)',
			position: 'fixed',
			bottom: '0',
			width: '100vw',
			left: '0',
		}}>
			<div className="w-[100%] h-[73px] bg-white border-b border-gray-200 flex-col justify-start items-center inline-flex">
				<div className="w-[100%] h-[72px] px-8 justify-between items-center inline-flex">
					<div className='flex ml-16 items-center'>
						<div className="mr-2 ml-4 text-gray-600 text-m font-semibold font-['Inter'] leading-7">
							Export to:
						</div>
						<div className="cursor-pointer mr-4 px-3.5 py-2 bg-blue-900 rounded-lg shadow border border-blue-900 justify-start items-center gap-2 flex"
							onClick={async () => {
								estimateStore.current.exportMessage = 'Exporting to Xactimate';
								estimateStore.current.exportState = 0;
								await SharedUtils.sleep(100);
								estimateStore.current.exportState = 4;
								await SharedUtils.sleep(2800);
								estimateStore.current.exportState = -1;
								notify('Xactimate');
							}}
						>
							<div className="text-white text-sm font-semibold font-['Hanken Grotesk'] leading-tight">Xactimate</div>
						</div>
						<div className="cursor-pointer mr-4 px-3.5 py-2 bg-blue-900 rounded-lg shadow border border-blue-900 justify-start items-center gap-2 flex"
							onClick={async () => {
								estimateStore.current.exportMessage = 'Exporting to Symbility';
								estimateStore.current.exportState = 0;
								await SharedUtils.sleep(100);
								estimateStore.current.exportState = 4;
								await SharedUtils.sleep(2800);
								estimateStore.current.exportState = -1;
								notify('Symbility');
							}}
						>
							<div className="text-white text-sm font-semibold font-['Hanken Grotesk'] leading-tight">Symbility</div>
						</div>
						<div className="cursor-pointer px-3.5 py-2 bg-blue-900 rounded-lg shadow border border-blue-900 justify-start items-center gap-2 flex"
							onClick={async () => {
								estimateStore.current.exportMessage = 'Exporting PDF';
								estimateStore.current.exportState = 0;
								await SharedUtils.sleep(100);
								estimateStore.current.exportState = 4;
								await SharedUtils.sleep(2800);
								PrivateUtils.exportPDF();
								estimateStore.current.exportState = -1;
								notify('PDF');
							}}
						>
							<div className="text-white text-sm font-semibold font-['Hanken Grotesk'] leading-tight">PDF File</div>
						</div>
					</div>
					<div className="justify-start items-center gap-4 flex">
						<div className="justify-start items-start gap-1 flex">
							<div className="self-stretch pl-10 flex-col justify-center items-end gap-1 inline-flex mr-8">
								<div className="text-right text-slate-600 text-sm font-medium font-['Inter'] leading-tight">Net Claim</div>
								<div className="text-slate-700 text-base font-medium font-['Hanken Grotesk'] leading-normal">${estimateStore.current.netClaim.toFixed(2)}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ToastContainer
			toastClassName="w-[450px] left-[-50%]"
			position="bottom-center"
			autoClose={2000}
			hideProgressBar
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss={false}
			draggable={false}
			pauseOnHover={false}
			theme="light"
		/>
	</div>
});

export default LassoPage;
