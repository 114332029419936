import { observer } from 'mobx-react';
import { useRef } from 'react';
import { getUserStore } from '../../../data/DataStores';

const LassoUserImage: React.FC = observer(() => {
	const userStore = useRef(getUserStore());
	return (
		<img className="w-10 h-10 rounded-full" src={userStore.current.user?.photoURL ?? ''} alt="Rounded avatar"></img>
	);
});

export default LassoUserImage;